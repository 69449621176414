import { LOG_IN, LOG_OUT } from './loginActionTypes';

const initialState = {
    user: null,
};

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOG_IN:
            return { ...state, user: action.payload.user };
        case LOG_OUT:
            return { ...state, user: null };
        default:
            return state;
    }
}
