export default [
    {
        title: 'Střešní boxy',
        url: '/shop',
        products: 6,
        image: 'images/categories/category-1.jpg',
        subcategories: [
            { title: 'Screwdrivers', url: '/shop' },
        ],
    },
    {
        title: 'Střešní nosiče',
        url: '/shop',
        products: 5,
        image: 'images/categories/category-2.jpg',
        subcategories: [
            { title: 'Screwdrivers', url: '/shop' },
        ],
    },
    {
        title: 'Dětské sedačky',
        url: '/shop',
        products: 1,
        image: 'images/categories/category-3.jpg',
        subcategories: [
            { title: 'Lathes', url: '/shop' },
        ],
    },
];
