import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import availabilityData from './availability';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: 'Barva',
        slug: 'color',
        values: [
            { name: 'White', slug: 'white' },
            { name: 'Silver', slug: 'silver' },
            { name: 'Light Gray', slug: 'light-gray' },
            { name: 'Gray', slug: 'gray' },
            { name: 'Dark Gray', slug: 'dark-gray' },
            { name: 'Coal', slug: 'coal' },
            { name: 'Černá', slug: 'black' },
            { name: 'Červená', slug: 'red' },
            { name: 'Orange', slug: 'orange' },
            { name: 'Yellow', slug: 'yellow' },
            { name: 'Pear Green', slug: 'pear-green' },
            { name: 'Green', slug: 'green' },
            { name: 'Emerald', slug: 'emerald' },
            { name: 'Shamrock', slug: 'shamrock' },
            { name: 'Shakespeare', slug: 'shakespeare' },
            { name: 'Modrá', slug: 'blue' },
            { name: 'Dark Blue', slug: 'dark-blue' },
            { name: 'Violet', slug: 'violet' },
            { name: 'Purple', slug: 'purple' },
            { name: 'Cerise', slug: 'cerise' },
        ],
    },
    {
        name: 'Speed',
        slug: 'speed',
        values: [
            { name: '750 RPM', slug: '750-rpm' },
        ],
    },
    {
        name: 'Objem netto',
        slug: 'volume-net',
        values: [],
    },
    {
        name: 'Vnitřní rozměry',
        slug: 'inner-dimension',
        values: [],
    },
    {
        name: 'Vnější rozměry',
        slug: 'outer-dimension',
        values: [],
    },
    {
        name: 'Maximální počet lyží',
        slug: 'max-ski-count',
        values: [],
    },
    {
        name: 'Maximální délka lyží',
        slug: 'max-ski-length',
        values: [],
    },
    {
        name: 'Hmotnost',
        slug: 'weight',
        values: [],
    },
    {
        name: 'Nosnost',
        slug: 'load-capacity',
        values: [],
    },
    {
        name: 'Otevírání',
        slug: 'openning',
        values: [],
    },
];

const productsDef = [
    {
        slug: 'stresni-box-md3',
        name: 'Střešní box MD3',
        prices: {
            rent: 90,
            sell: 4210
        },
        compareAtPrice: {
        },
        images: [
            'images/products/md3/01.jpg',
            'images/products/md3/02.jpg',
            'images/products/md3/03.jpg',
            'images/products/md3/04.jpg',
            'images/products/md3/05.jpg',
            'images/products/md3/06.jpg',
            'images/products/md3/07.jpg',
            'images/products/md3/08.jpg',
            'images/products/md3/09.jpg',
            'images/products/md3/10.jpg',
            'images/products/md3/11.jpg',
        ],
        badges: {
            rent: 'new',
            sell: 'new',
        },
        rating: 5,
        reviews: 1,
        availability: 'in-stock',
        brand: 'up',
        categories: ['stresni-boxy'],
        attributes: [
            { slug: 'color', values: ['black'] },
            { slug: 'volume-net', values: 250, featured: true },
            { slug: 'inner-dimension', values: '1710x440x340' },
            { slug: 'outer-dimension', values: '1780x520x350' },
            { slug: 'max-ski-count', values: 3 },
            { slug: 'max-ski-length', values: 1570 },
            { slug: 'weight', values: 9 },
            { slug: 'load-capacity', values: 75 },
            { slug: 'openning', values: 'boční, vpravo, plynové vzpěry' },
        ],
        description: 'Střešní box MD 3 má univerzální použití na zimní i letní sezónu. Tento střešní box je vhodný na všechny modely automobilů a všechny modely střešních nosičů. "Ekonomický" box má rozměry: 178 x 52 x 35 cm a objem netto 250 litrů. Upevnění boxu je ideální na mezilyžinové nosiče a střešní nosiče s T- drážkou pomocí T-Bolt, ale je vhodný i na všechny ostatní modely aut a všechny typy střešních nosičů. Box je vybaven dvěma bezpečnostními standardními zámky Eurolock a je uzamykatelný jedním klíčem.',
    },
    {
        slug: 'stresni-box-md5',
        name: 'Střešní box MD5',
        prices: {
            rent: 90,
            sell: 4800
        },
        compareAtPrice: {
        },
        images: [
            'images/products/md5/01.jpg',
            'images/products/md5/02.jpg',
            'images/products/md5/03.jpg',
            'images/products/md5/04.jpg',
            'images/products/md5/05.jpg',
            'images/products/md5/06.jpg',
            'images/products/md5/07.jpg',
            'images/products/md5/08.jpg',
        ],
        rating: 5,
        reviews: 1,
        availability: 'in-stock',
        brand: 'up',
        categories: ['stresni-boxy'],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'volume-net', values: 350, featured: true },
            { slug: 'inner-dimension', values: '1245x825x380' },
            { slug: 'outer-dimension', values: '1300x900x390' },
            { slug: 'max-ski-count', values: 3 },
            { slug: 'max-ski-length', values: 1210 },
            { slug: 'weight', values: 10.5 },
            { slug: 'load-capacity', values: 75 },
            { slug: 'openning', values: 'boční, vpravo, plynové vzpěry' },
        ],
        description: 'Střešní box MD 5 má univerzální použití na zimní i letní sezónu. Tento střešní box je vhodný na všechny modely automobilů a všechny modely střešních nosičů. "Zavazadlový" box má rozměry: 130 x 90 x 39 cm a objem netto 350 litrů. Upevnění boxu je ideálně pomocí U- třmenů nebo T - Bolt / uchytí. Box je vybaven jedním bezpečnostním standardním zámkem Eurolock a je uzamykatelný jedním klíčem.',
    },
    {
        slug: 'stresni-box-md15',
        name: 'Střešní box MD15',
        prices: {
            rent: 100,
            sell: 6230
        },
        compareAtPrice: {
        },
        images: [
            'images/products/md15/01.jpg',
            'images/products/md15/02.jpg',
            'images/products/md15/03.jpg',
            'images/products/md15/04.jpg',
            'images/products/md15/05.jpg',
            'images/products/md15/06.jpg',
            'images/products/md15/07.jpg',
            'images/products/md15/08.jpg',
            'images/products/md15/09.jpg',
            'images/products/md15/10.jpg',
            'images/products/md15/11.jpg',
        ],
        badges: {
            sell: 'sale',
        },
        rating: 5,
        reviews: 1,
        availability: 'in-stock',
        brand: 'up',
        categories: ['stresni-boxy'],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'volume-net', values: 330, featured: true },
            { slug: 'inner-dimension', values: '1890x600x360' },
            { slug: 'outer-dimension', values: '1970x670x370' },
            { slug: 'max-ski-count', values: 3 },
            { slug: 'max-ski-length', values: 1800 },
            { slug: 'weight', values: 12.5 },
            { slug: 'load-capacity', values: 75 },
            { slug: 'openning', values: 'boční, vpravo, plynové vzpěry' },
        ],
        description: 'Střešní box MD 15 má univerzální použití na zimní i letní sezónu. Tento střešní box je vhodný na všechny modely automobilů a všechny modely střešních nosičů. "Karvingový" box má rozměry: 197 x 67 x 37 cm a objem netto 330 litrů. Upevnění boxu je ideálně pomocí U- třmenů nebo T - Bolt / uchytí. Box je vybaven jedním bezpečnostním standardním zámkem Eurolock a je uzamykatelný jedním klíčem.',
    },
    {
        slug: 'stresni-box-md17',
        name: 'Střešní box MD17',
        prices: {
            rent: 120,
            sell: 6710
        },
        compareAtPrice: {
        },
        images: [
            'images/products/md17/01.jpg',
            'images/products/md17/02.jpg',
            'images/products/md17/03.jpg',
            'images/products/md17/04.jpg',
            'images/products/md17/05.jpg',
            'images/products/md17/06.jpg',
            'images/products/md17/07.jpg',
            'images/products/md17/08.jpg',
            'images/products/md17/09.jpg',
            'images/products/md17/10.jpg',
            'images/products/md17/11.jpg',
        ],
        badges: {
        },
        rating: 5,
        reviews: 1,
        availability: 'in-stock',
        brand: 'up',
        categories: ['stresni-boxy'],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'volume-net', values: 400, featured: true },
            { slug: 'inner-dimension', values: '1835x740x350' },
            { slug: 'outer-dimension', values: '1890x810x360' },
            { slug: 'max-ski-count', values: 6 },
            { slug: 'max-ski-length', values: 1710 },
            { slug: 'weight', values: 14 },
            { slug: 'load-capacity', values: 75 },
            { slug: 'openning', values: 'boční, vpravo, plynové vzpěry' },
        ],
        description: 'Střešní box MD 17 má univerzální použití na zimní i letní sezónu. Tento střešní box je vhodný na všechny modely automobilů a všechny modely střešních nosičů. "Racio" box má rozměry: 189 x 81 x 36 cm cm a objem netto 400 litrů. Upevnění boxu je ideální na mezilyžinové nosiče a střešní nosiče s T- drážkou pomocí T-Bolt, ale je vhodný i na všechny ostatní modely aut a všechny typy střešních nosičů. Box je stadardem vybaven centrálním tříbodovým zámkem.',
    },
    {
        slug: 'stresni-box-md19',
        name: 'Střešní box MD19',
        prices: {
            rent: 150,
            sell: 7360
        },
        compareAtPrice: {
        },
        images: [
            'images/products/md19/01.jpg',
            'images/products/md19/02.jpg',
            'images/products/md19/03.jpg',
            'images/products/md19/04.jpg',
            'images/products/md19/05.jpg',
            'images/products/md19/06.jpg',
            'images/products/md19/07.jpg',
            'images/products/md19/08.jpg',
            'images/products/md19/09.jpg',
            'images/products/md19/10.jpg',
            'images/products/md19/11.jpg',
        ],
        badges: {
            sell: ['sale'],
        },
        rating: 5,
        reviews: 1,
        availability: 'in-stock',
        brand: 'up',
        categories: ['stresni-boxy'],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'volume-net', values: 500, featured: true },
            { slug: 'inner-dimension', values: '1980x825x370' },
            { slug: 'outer-dimension', values: '2050x880x380' },
            { slug: 'max-ski-count', values: 10 },
            { slug: 'max-ski-length', values: 1850 },
            { slug: 'weight', values: 17 },
            { slug: 'load-capacity', values: 75 },
            { slug: 'openning', values: 'boční, vpravo, plynové vzpěry' },
        ],
        description: 'Střešní box MD 19 má univerzální použití na zimní i letní sezónu. Tento střešní box je vhodný na všechny modely automobilů a všechny modely střešních nosičů. "Lite expediční" box má rozměry: 205 x 88 x 38 cm cm a objem netto 500 litrů. Upevnění boxu je ideální na mezilyžinové nosiče a střešní nosiče s T- drážkou pomocí T-Bolt, ale je vhodný i na všechny ostatní modely aut a všechny typy střešních nosičů. Box je stadardem vybaven centrálním tříbodovým zámkem.',
    },
    {
        slug: 'stresni-nosic-menamo-brio',
        name: 'Střešní nosič Menamo Brio univerzální',
        prices: {
            rent: 90,
            sell: 2000
        },
        compareAtPrice: {
        },
        images: [
            'images/products/stresnit-nosic-menabo-brio/01.jpg',
        ],
        rating: 5,
        reviews: 5,
        availability: 'in-stock',
        brand: 'menamo',
        categories: ['stresni-nosice'],
        attributes: [
            { slug: 'color', values: ['silver'] },
            { slug: 'load-capacity', values: 90 },
            { slug: 'weight', values: 3.5 },
        ],
        description: 'Příčníky Menabo Brio jsou universální střešní nosiče dlouhé 1200mm, jsou uzamykatelné a mají nostnost 90kg. Montáž na podélník se provádí pomocí imbusového klíče. Profil nosiče je hliníkový s eloxovaným povrchem. Příčníky váží 3,5kg.',
    }
];

const productsData = productsDef.map((productDef) => {
    let badges = {};

    if (productDef.badges) {
        Object.entries(productDef.badges).forEach(([key, value]) => typeof value === 'string' ? badges[key] = [value] : badges[key] = value);
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));

    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let values = [];

        if (attributeDef.values.length === 0) {
            if (typeof productAttributeDef.values === 'string' || typeof productAttributeDef.values === 'number') {
                values = [{ name: productAttributeDef.values, slug: productAttributeDef.values }];
            }
        } else {
            let valuesDef = [];

            if (typeof productAttributeDef.values === 'string' || typeof productAttributeDef.values === 'number') {
                valuesDef = [productAttributeDef.values];
            } else if (productAttributeDef.values) {
                valuesDef = productAttributeDef.values;
            }

            values = valuesDef.map((valueSlug) => {
                const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

                if (!valueDef) {
                    return null;
                }

                return {
                    ...valueDef,
                    customFields: {},
                };
            }).filter((x) => x !== null);
        }

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: '83690/32',
        slug: productDef.slug,
        prices: productDef.prices,
        description: productDef.description,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: Object.assign({}, badges),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: availabilityData.find((x) => x.slug === productDef.availability) || null,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
