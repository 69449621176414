// react
import React, { useState } from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import ContactForm from '../shared/ContactForm';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

function SitePageContactUs() {
  const breadcrumb = [
    { title: 'Domů', url: '' },
    { title: 'Kontakt', url: '' },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Kontakt — ${theme.name}`}</title>
      </Helmet>

      <BlockMap />

      <PageHeader header="Kontakt" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="card mb-0">
            <div className="card-body contact-us">
              <div className="contact-us__container">
                <div className="row">
                  <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                    <h4 className="contact-us__header card-title">
                      <FormattedMessage id="sitePageContact.contactUs" defaultMessage="Contact Us" />
                    </h4>

                    <div className="contact-us__address">
                      <p>
                        Masarykova 633, 400 01 Ústí nad Labem
                        <br />
                        Email: <a href="mailto:lukas.cermak@yumer.cz">lukas.cermak@yumer.cz</a>
                        <br />
                        Telefonní číslo: +420 724 181 713
                      </p>

                      <p>
                        <strong>
                          <FormattedMessage id="sitePageContact.openningHours" defaultMessage="Openning Hours" />
                        </strong>
                        <br />
                        Po-Pá: 7:00-17:00
                        <br />
                        So-Ne: dohodou
                      </p>

                      <p>
                        <strong>
                          <FormattedMessage id="sitePageContact.comment" defaultMessage="Comment" />
                        </strong>
                        <br />
                        Sídlíme v garážích za tou velkou a vysokou budovou na Rondelu.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <h4 className="contact-us__header card-title">
                      <FormattedMessage id="sitePageContact.leaveUsMessage" defaultMessage="Leave us a Message" />
                    </h4>

                    <ContactForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageContactUs;
