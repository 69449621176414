// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setSellMode, setRentMode } from '../../store/toggle';

function Toggle(props) {
  const { mode, setSellMode, setRentMode } = props;
  return (
    <div className="toggle btn-group btn-group-toggle" data-toggle="buttons">
      <label className={classNames('btn btn-primary', { active: mode === 'rent' })} onClick={setRentMode}>
        <input type="radio" name="options" id="rent" autoComplete="off" /> Pronajmout
      </label>
      <label className={classNames('btn btn-primary', { active: mode === 'sell' })} onClick={setSellMode}>
        <input type="radio" name="options" id="sell" autoComplete="off" /> Koupit
      </label>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mode: state.toggle.mode,
});

const mapDispatchToProps = {
  setSellMode,
  setRentMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toggle);
