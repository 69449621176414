import { LOG_IN, LOG_OUT } from './loginActionTypes';

export function logIn(user) {
    // user logged in
    return { type: LOG_IN, payload: { user: user } };
}

export function logOut() {
    // user logged out
    return { type: LOG_OUT, payload: { user: null } };
}
