import CategoryFilterBuilder from '../filters/category';
import CheckFilterBuilder from '../filters/check';
import productsData from '../database/products';
import RadioFilterBuilder from '../filters/range';
import ColorFilterBuilder from '../filters/color';
import RangeFilterBuilder from '../filters/price';

function getProductsData(mode = 'rent') {
    return productsData.map((element) => {
        element.price = element.prices[mode];
        return element;
    })
}

function getProducts(shift, options) {
    const limit = options.limit || 8;
    let products = getProductsData(options.mode);

    if (options.categories) {
        products = getProductsData(options.mode).filter((element) => {
            return element.categories.reduce((previousValue, currentValue) => {
                return previousValue || (options.categories.includes(currentValue.slug));
            }, false);
        });
    }

    return new Promise((resolve) => resolve(products.slice(0, limit)));
}

export function getProductBySlug(slug, options) {
    const product = getProductsData(options.mode).find((x) => x.slug === slug);

    return product ? Promise.resolve(product) : Promise.reject();
}

export function getRelatedProducts(slug, options) {
    const limit = options.limit || 8;

    return Promise.resolve(getProductsData(options.mode).slice(0, limit));
}

export function getProductsList(options, filterValues = {}) {
    const filters = [
        new CategoryFilterBuilder('category', 'Kategorie'),
        new RangeFilterBuilder('price', 'Cena'),
        new CheckFilterBuilder('brand', 'Značka'),
        new RadioFilterBuilder('discount', 'Sleva'),
        new ColorFilterBuilder('color', 'Barva'),
    ];

    let items = getProductsData(options.mode).slice(0);

    filters.forEach((filter) => filter.makeItems(items, filterValues[filter.slug]));

    // Calculate items count for filter values.
    filters.forEach((filter) => filter.calc(filters));

    // Apply filters to products list.
    items = items.filter(
        (product) => filters.reduce((mr, filter) => mr && filter.test(product), true),
    );

    const page = options.page || 1;
    const limit = options.limit || 12;
    const sort = options.sort || 'default';
    const total = items.length;
    const pages = Math.ceil(total / limit);
    const from = (page - 1) * limit + 1;
    const to = Math.max(Math.min(page * limit, total), from);

    items = items.sort((a, b) => {
        if (['name_asc', 'name_desc'].includes(sort)) {
            if (a.name === b.name) {
                return 0;
            }

            return (a.name > b.name ? 1 : -1) * (sort === 'name_asc' ? 1 : -1);
        }

        return 0;
    });

    const start = (page - 1) * limit;
    const end = start + limit;

    items = items.slice(start, end);

    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                page,
                limit,
                sort,
                total,
                pages,
                from,
                to,
                items,
                filters: filters.map((x) => x.build()),
            });
        }, 350);
    });
}

export function getFeaturedProducts(options) {
    return getProducts(0, options);
}

export function getLatestProducts(options) {
    return getProducts(0, options);
}

export function getTopRatedProducts(options) {
    return getProducts(0, options);
}

export function getDiscountedProducts(options) {
    return getProducts(3, options);
}

export function getPopularProducts(options) {
    return getProducts(6, options);
}

export function getSuggestions(query, options) {
    const limit = options.limit || 5;

    return Promise.resolve(
        getProductsData(options.mode).filter(
            (x) => x.name.toLowerCase().includes(query.toLowerCase()),
        ).slice(0, limit),
    );
}
