// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import StroykaSlick from '../shared/StroykaSlick';

// data stubs
import theme from '../../data/theme';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SitePageAboutUs() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`O nás — ${theme.name}`}</title>
            </Helmet>

            <div className="about-us__image" style={{ backgroundImage: 'url("images/aboutus.jpg")' }} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">O nás</h1>
                            <div className="about-us__text typography">
                                <p>
                                    Jsme půjčovna o třech lidech a budeme rádi, když si střešní box nebo jiné vybavení půjčíte právě u nás.
                                </p>
                                <ul>
                                    <li>Neúčtujeme první, ale ani poslední den zápůjčky</li>
                                    <li>Odborná monáž nosičů je zdarma</li>
                                    <li>Minimální zálohy</li>
                                </ul>
                                <p>
                                    Nabízíme střešní box, střešní nosiče a autosedačky.
                                </p>
                            </div>
                            <div className="about-us__team">
                                <h2 className="about-us__team-title">Seznamte se s naším týmem</h2>
                                <div className="about-us__team-subtitle text-muted">
                                    Potřebujete poradit? 
                                    <br />
                                    <Link to="/site/contact-us">Kontaktujte nás</Link>
                                </div>
                                <div className="about-us__teammates teammates">
                                    <StroykaSlick {...slickSettings}>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-1.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Lukáš Čermák</div>
                                            <div className="teammate__position text-muted">Non-stop podpora</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-2.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Luboš Čermák</div>
                                            <div className="teammate__position text-muted">Odborná montáž</div>
                                        </div>
                                        <div className="teammates__item teammate">
                                            <div className="teammate__avatar">
                                                <img src="images/teammates/teammate-3.jpg" alt="" />
                                            </div>
                                            <div className="teammate__name">Martin Pelák</div>
                                            <div className="teammate__position text-muted">Non-stop podpora</div>
                                        </div>
                                    </StroykaSlick>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageAboutUs;
