import { TOGGLE_MODE } from './toggleActionTypes';

const initialState = {
    mode: 'rent',
};

export default function toggleReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MODE:
            return { ...state, mode: action.payload.mode };
        default:
            return state;
    }
}
