// react
import React, { useState, useCallback } from 'react';

// third-party>
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function ContactForm(props) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [submitErrors, setSubmitErrors] = useState({});

  const handleSubmit = async (values) => {
    const result = await executeRecaptcha('contactForm');

    let host = 'https://us-central1-boxy-287212.cloudfunctions.net/submitForm';
    //let host = 'http://localhost:5001/boxy-287212/us-central1/submitForm';
    return await axios
      .post(host, {
        name: values.name,
        subject: values.subject,
        email: values.email,
        message: values.message,
        token: result,
      })
      .then(() => {
        return { success: 'Zpráva byla úspěšně odeslána.' };
      })
      .catch(() => {
        return { message: 'Zprávu se nepodařilo odeslat.' };
      });
  };

  return (
    <Formik
      initialValues={{ name: '', email: '', subject: '', message: '' }}
      validationSchema={Yup.object({
        name: Yup.string().required('Vyžadováno'),
        email: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
        subject: Yup.string().required('Vyžadováno'),
        message: Yup.string().required('Vyžadováno'),
      })}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        setTimeout(async () => {
          const result = await handleSubmit(values);
          setSubmitErrors(result);
          setSubmitting(false);

          if (result.success) {
            resetForm();
          }
        }, 1000);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <React.Fragment>
          {console.log(errors)}
          {submitErrors.message && <div className="alert alert-danger mb-3">{submitErrors.message}</div>}
          {submitErrors.success && <div className="alert alert-success mb-3">{submitErrors.success}</div>}
          <Form noValidate>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="form-name">
                  <FormattedMessage id="sitePageContact.yourName" defaultMessage="Your Name" />
                </label>
                <FormattedMessage id="sitePageContact.yourName" defaultMessage="Your Name">
                  {(placeholder) => (
                    <Field
                      type="text"
                      id="name"
                      className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`}
                      name="name"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
                <ErrorMessage name="name" component="div" className="invalid-feedback" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="form-email">Email</label>
                <FormattedMessage id="sitePageContact.emailAddress" defaultMessage="Email">
                  {(placeholder) => (
                    <Field
                      type="email"
                      id="email"
                      className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                      name="email"
                      placeholder={placeholder}
                    />
                  )}
                </FormattedMessage>
                <ErrorMessage name="email" component="div" className="invalid-feedback" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="subject">
                <FormattedMessage id="sitePageContact.subject" defaultMessage="Subject" />
              </label>
              <FormattedMessage id="sitePageContact.subject" defaultMessage="Subject">
                {(placeholder) => (
                  <Field
                    type="text"
                    id="subject"
                    className={`form-control ${touched.subject && errors.subject ? 'is-invalid' : ''}`}
                    name="subject"
                    placeholder={placeholder}
                  />
                )}
              </FormattedMessage>
              <ErrorMessage name="subject" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label htmlFor="message">
                <FormattedMessage id="sitePageContact.message" defaultMessage="Message" />
              </label>
              <Field
                id="message"
                className={`form-control ${touched.message && errors.message ? 'is-invalid' : ''}`}
                rows="4"
                name="message"
                component="textarea"
              />
              <ErrorMessage name="message" component="div" className="invalid-feedback" />
            </div>
            <button
              type="submit"
              className={classNames('btn btn-primary', {
                'btn-loading': isSubmitting,
              })}
            >
              <FormattedMessage id="sitePageContact.sendMessage" defaultMessage="Send Message" />
            </button>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );
}

export default ContactForm;
