import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_REMOVE_ITEM, CART_UPDATE_QUANTITIES, CART_ADD_CHECKOUT_VALUES, CART_CLEAR } from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], quantity = 1, extra) {
    toast.success(`Produkt "${product.name}" byl přidán do košíku!`);

    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        extra
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities, rentalDates) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
        rentalDates,
    };
}

export function cartAddCheckoutValuesSuccess(checkoutValues) {
    return {
        type: CART_ADD_CHECKOUT_VALUES,
        checkoutValues,
    };
}

export function cartClearSuccess() {
    return {
        type: CART_CLEAR,
    }
}

export function cartAddItem(product, options = [], quantity = 1, extra) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, options, quantity, extra));
                resolve();
            }, 500);
        })
    );
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        })
    );
}

export function cartUpdateQuantities(quantities, rentalDates) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities, rentalDates));
                resolve();
            }, 500);
        })
    );
}

export function cartAddCheckoutValues(checkoutValues) {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddCheckoutValuesSuccess(checkoutValues));
                resolve();
            }, 500);
        })
    );
}

export function cartClear() {
    // sending request to server, timeout is used as a stub
    return (dispatch) => (
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartClearSuccess());
                resolve();
            }, 500);
        })
    );
}