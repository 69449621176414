// react
import React from 'react';

// application
import SocialLinks from '../shared/SocialLinks';

export default function Help() {
    return (
        <div className="site-footer__widget footer-help">
            <h5 className="footer-help__title">Nevíte si rady?</h5>
            <div className="footer-help__text">
                <p>
                    V klidu nám zavolejte nebo napište email.
                    <br />
                    Rádi Vám poradíme. Děkujeme!
                </p>
                <p>
                    +420 724 181 713
                    <br />
                    <a href="mailto:lukas.cermak@yumer.cz">lukas.cermak@yumer.cz</a>
                </p>
            </div>

            <SocialLinks className="footer-newsletter__social-links" shape="circle" />
        </div>
    );
}
