// react
import React from 'react';

// data stubs
import specification from '../../data/shopProductSpec';

function ProductTabSpecification(props) {
    const { product } = props;
    const section = product.attributes.map((attribute, index) => {
        return (
            <div key={index} className="spec__row">
                <div className="spec__name">{attribute.name}</div>
                <div className="spec__value">
                    {attribute.values.map((value) => (
                        value.name
                    )).join(', ')}
                </div>
            </div>
        );
    });

    return (
        <div className="spec">
            <h3 className="spec__header">Specifikace</h3>
            <div className="spec__section">
                <h4 className="spec__section-title">Technické parametry</h4>
                {section}
            </div>
            <div className="spec__disclaimer">
                Technické informace a vzhled produktů slouží pouze k referenční účelům a je založen na informacích dostupných v době publikace. 
            </div>
        </div>
    );
}

export default ProductTabSpecification;
