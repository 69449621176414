// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageTerms() {
  const breadcrumb = [
    { title: 'Domů', url: '' },
    { title: 'Obchodní podmínky', url: '' },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Obchodní podmínky — ${theme.name}`}</title>
      </Helmet>

      <PageHeader breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="document">
            <div className="document__header">
              <h1 className="document__title">Obchodní podmínky</h1>
              <div className="document__subtitle">Tato dohoda byla naposledy aktualizována 1. prosince 2020.</div>
            </div>
            <div className="document__content typography">
              <h2>I. Základní ustanovení</h2>
              <p>
                1. Tyto všeobecné obchodní podmínky (dále jen „obchodní podmínky“) jsou vydané dle § 1751 a násl. zákona
                č. 89/2012 Sb., občanský zákoník (dále jen „občanský zákoník“)
                <br />
                <br /> Yumer s. r. o.
                <br />
                IČ: 22801464
                <br />
                DIČ: CZ22801464
                <br />
                se sídlem: Klíšská 995/133, Klíše, 400 01 Ústí nad Labem
                <br />
                zapsané u Krajského soudu v Ústí nad Labem, spisová značka C 32437
                <br />
                e-mail: <a href="mailto:martin.pelak@yumer.cz">martin.pelak@yumer.cz</a>
                <br />
                telefon: +420 724 181 713
                <br />
                web: <a href="https://www.yumer.cz/">www.yumer.cz</a>
                <br />
                (dále jen „<strong>prodávající</strong>“)
              </p>
              <p>
                2. Tyto obchodní podmínky upravují vzájemná práva a povinnosti prodávajícího a fyzické osoby, která
                uzavírá kupní smlouvu mimo svou podnikatelskou činnost jako spotřebitel, nebo v rámci své podnikatelské
                činnosti (dále jen: „<strong>kupující</strong>“) prostřednictvím webového rozhraní umístěného na webové
                stránce dostupné na internetové adrese <a href="https://www.upboxy.cz">www.upboxy.cz</a>. (dále jen „
                <strong>internetový obchod</strong>“).
              </p>
              <p>
                3. Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Odchylná ujednání v kupní
                smlouvě mají přednost před ustanoveními těchto obchodních podmínek.
              </p>
              <p>4. Tyto obchodní podmínky a kupní smlouva se uzavírají v českém jazyce.</p>

              <h2>II. Informace o zboží a cenách</h2>
              <p>
                1. Informace o zboží, včetně uvedení cen jednotlivého zboží a jeho hlavních vlastností, jsou uvedeny u
                jednotlivého zboží v katalogu internetového obchodu. Ceny zboží jsou uvedeny včetně daně z přidané
                hodnoty, všech souvisejících poplatků a nákladů za vrácení zboží, jestliže toto zboží ze své podstaty
                nemůže být vráceno obvyklou poštovní cestou. Ceny zboží zůstávají v platnosti po dobu, po kterou jsou
                zobrazovány v internetovém obchodě. Toto ustanovení nevylučuje sjednání kupní smlouvy za individuálně
                sjednaných podmínek.
              </p>
              <p>
                2. Veškerá prezentace zboží umístěná v katalogu internetového obchodu je informativního charakteru a
                prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží.
              </p>
              <p>
                3. V internetovém obchodě jsou zveřejněny informace o nákladech spojených s balením a dodáním zboží.
                Informace o nákladech spojených s balením a dodáním zboží uvedené v internetovém obchodě platí pouze v
                případech, kdy je zboží doručováno v rámci území České republiky.
              </p>
              <p>
                4. Případné slevy z kupní ceny zboží nelze navzájem kombinovat, nedohodne-li se prodávající s kupujícím
                jinak.
              </p>

              <h2>III. Objednávka a uzavření kupní smlouvy</h2>
              <p>
                1. Náklady vzniklé kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením
                kupní smlouvy (náklady na internetové připojení, náklady na telefonní hovory), hradí kupující sám. Tyto
                náklady se neliší od základní sazby.
              </p>
              <p>
                2. Kupující provádí objednávku zboží těmito způsoby:
                <ul>
                  <li>
                    prostřednictvím svého zákaznického účtu, provedl-li předchozí registraci v internetovém obchodě,
                  </li>
                  <li>vyplněním objednávkového formuláře bez registrace.</li>
                </ul>
              </p>

              <p>3. Při zadávání objednávky si kupující vybere zboží, počet kusů zboží, způsob platby a doručení.</p>
              <p>
                4. Před odesláním objednávky je kupujícímu umožněno kontrolovat a měnit údaje, které do objednávky
                vložil. Objednávku kupující odešle prodávajícímu kliknutím na tlačítko Objednat. Údaje uvedené v
                objednávce jsou prodávajícím považovány za správné. Podmínkou platnosti objednávky je vyplnění všech
                povinných údajů v objednávkovém formuláři a potvrzení kupujícího o tom, že se s těmito obchodními
                podmínkami seznámil.
              </p>

              <p>
                5. Neprodleně po obdržení objednávky zašle prodávající kupujícímu potvrzení o obdržení objednávky na
                e-mailovou adresu, kterou kupující při objednání zadal. Toto potvrzení je automatické a nepovažuje se za
                uzavření smlouvy. Přílohou potvrzení jsou aktuální obchodní podmínky prodávajícího. Kupní smlouva je
                uzavřena až po přijetí objednávky prodávajícím. Oznámení o přijetí objednávky je doručeno na e-mailovou
                adresu kupujícího.
              </p>

              <p>
                6. V případě, že některý z požadavků uvedených v objednávce nemůže prodávající splnit, zašle kupujícímu
                na jeho e-mailovou adresu pozměněnou nabídku. Pozměněná nabídka se považuje za nový návrh kupní smlouvy
                a kupní smlouva je v takovém případě uzavřena potvrzením kupujícího o přijetí této nabídky prodávajícímu
                na jeho e-mailovou adresu uvedenou v těchto obchodních podmínkách.
              </p>

              <p>
                7. Všechny objednávky přijaté prodávajícím jsou závazné. Kupující může zrušit objednávku, dokud není
                kupujícímu doručeno oznámení o přijetí objednávky prodávajícím. Kupující může zrušit objednávku
                telefonicky na telefonní číslo nebo e-mail prodávajícího uvedený v těchto obchodních podmínkách.
              </p>

              <p>
                8. V případě, že došlo ke zjevné technické chybě na straně prodávajícího při uvedení ceny zboží v
                internetovém obchodě nebo v průběhu objednávání, není prodávající povinen dodat kupujícímu zboží za tuto
                zcela zjevně chybnou cenu ani v případě, že kupujícímu bylo zasláno automatické potvrzení o obdržení
                objednávky podle těchto obchodních podmínek. Prodávající informuje kupujícího o chybě bez zbytečného
                odkladu a zašle kupujícímu na jeho e-mailovou adresu pozměněnou nabídku. Pozměněná nabídka se považuje
                za nový návrh kupní smlouvy a kupní smlouva je v takovém případě uzavřena potvrzením o přijetí kupujícím
                na e-mailovou adresu prodávajícího.
              </p>

              <h2>IV. Zákaznický účet</h2>

              <p>
                1. Na základě registrace kupujícího provedené v internetovém obchodě může kupující přistupovat do svého
                zákaznického účtu. Ze svého zákaznického účtu může kupující provádět objednávání zboží. Kupující může
                objednávat zboží také bez registrace.
              </p>

              <p>
                2. Při registraci do zákaznického účtu a při objednávání zboží je kupující povinen uvádět správně a
                pravdivě všechny údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen
                aktualizovat. Údaje uvedené kupujícím v zákaznickém účtu a při objednávání zboží jsou prodávajícím
                považovány za správné.
              </p>

              <p>
                3. Přístup k zákaznickému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen
                zachovávat mlčenlivost ohledně informací nezbytných k přístupu do jeho zákaznického účtu. Prodávající
                nenese odpovědnost za případné zneužití zákaznického účtu třetími osobami.
              </p>

              <p>4. Kupující není oprávněn umožnit využívání zákaznického účtu třetím osobám.</p>

              <p>
                5. Prodávající může zrušit uživatelský účet a to zejména v případě, když kupující svůj uživatelský účet
                déle nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy nebo těchto obchodních
                podmínek.
              </p>

              <p>
                6. Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě a to zejména s ohledem
                na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a
                softwarového vybavení třetích osob.
              </p>

              <h2>V. Platební podmínky a dodání zboží</h2>

              <p>
                1. Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit
                následujícími způsoby:
                <ul>
                  <li>
                    bezhotovostně převodem na bankovní účet prodávajícího č. 2700348226 / 2010., vedený u Fio banka,
                    a.s.,
                  </li>
                  <li>bezhotovostně platební kartou,</li>
                  <li>bezhotovostně převodem na účet prodávajícího prostřednictvím platební brány, </li>
                  <li>dobírkou v hotovosti při předání zboží,</li>
                  <li>v hotovosti nebo platební kartou při osobním odběru v provozovně,</li>
                  <li>v hotovosti nebo platební kartou při osobním odběru výdejně zásilek.</li>
                </ul>
              </p>

              <p>
                2. Společně s kupní cenou je kupující povinen uhradit prodávajícímu náklady spojené s balením a dodáním
                zboží ve smluvené výši. Není-li dále uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady
                spojené s dodáním zboží
              </p>

              <p>
                3. V případě platby v hotovosti je kupní cena splatná při převzetí zboží. V případě bezhotovostní platby
                je kupní cena splatná do 5 dnů od uzavření kupní smlouvy.
              </p>

              <p>
                4. V případě platby prostřednictvím platební brány postupuje kupující podle pokynů příslušného
                poskytovatele elektronických plateb.
              </p>

              <p>
                5. V případě bezhotovostní platby je závazek kupujícího uhradit kupní cenu splněn okamžikem připsání
                příslušné částky na bankovní účet prodávajícího.
              </p>

              <p>
                6. Prodávající nepožaduje od kupujícího předem žádnou zálohu či jinou obdobnou platbu. Úhrada kupní ceny
                před odesláním zboží není zálohou.
              </p>

              <p>
                7. Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen
                zaevidovat přijatou tržbu u správce daně online, v případě technického výpadku pak nejpozději do 48
                hodin
              </p>

              <p>
                8. Zboží je kupujícímu dodáno:
                <ul>
                  <li>na adresu určenou kupujícím objednávce</li>
                  <li>prostřednictvím výdejny zásilek na adresu výdejny, kterou kupující určil,</li>
                  <li>osobním odběrem v provozovně prodávajícího.</li>
                </ul>
              </p>
              <p>9. Volba způsobu dodání se provádí během objednávání zboží.</p>
              <p>
                10. Náklady na dodání zboží v závislosti na způsobu odeslání a převzetí zboží jsou uvedeny v objednávce
                kupujícího a v potvrzení objednávky prodávajícím. V případě, že je způsob dopravy smluven na základě
                zvláštního požadavku kupujícího, nese kupující riziko a případné dodatečné náklady spojené s tímto
                způsobem dopravy.
              </p>
              <p>
                11. Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je
                kupující povinen převzít zboží při dodání. V případě, že je z důvodů na straně kupujícího nutno zboží
                doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující povinen uhradit
                náklady spojené s opakovaným doručováním zboží, resp. náklady spojené s jiným způsobem doručení.
              </p>
              <p>
                12. Při převzetí zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a v
                případě jakýchkoliv závad toto neprodleně oznámit přepravci. V případě shledání porušení obalu
                svědčícího o neoprávněném vniknutí do zásilky nemusí kupující zásilku od přepravce převzít.
              </p>
              <p>
                13. Prodávající vystaví kupujícímu daňový doklad – fakturu. Daňový doklad je odeslán na e-mailovou
                adresu kupujícího. / Daňový doklad je přiložen k dodávanému zboží.
              </p>
              <p>
                14. Kupující nabývá vlastnické právo ke zboží zaplacením celé kupní ceny za zboží včetně nákladů na
                dodání, nejdříve však převzetím zboží.
              </p>
              <p>
                15. Odpovědnost za nahodilou zkázu, poškození či ztrátu zboží přechází na kupujícího okamžikem převzetí
                zboží nebo okamžikem, kdy měl kupující povinnost zboží převzít, ale v rozporu s kupní smlouvou tak
                neučinil.
              </p>

              <h2>VI. Odstoupení od smlouvy</h2>

              <p>
                1. Kupující, který uzavřel kupní smlouvu mimo svou podnikatelskou činnost jako spotřebitel, má právo od
                kupní smlouvy odstoupit.
              </p>
              <p>
                2. Lhůta pro odstoupení od smlouvy činí 14 dnů
                <ul>
                  <li>ode dne převzetí zboží,</li>
                  <li>
                    ode dne převzetí poslední dodávky zboží, je-li předmětem smlouvy několik druhů zboží nebo dodání
                    několika částí,
                  </li>
                  <li>
                    ode dne převzetí první dodávky zboží, je-li předmětem smlouvy pravidelná opakovaná dodávka zboží.
                  </li>
                </ul>
              </p>

              <p>
                3. Kupující nemůže mimo jiné odstoupit od kupní smlouvy
                <ul>
                  <li>
                    poskytování služeb, jestliže byly splněny s jeho předchozím výslovným souhlasem před uplynutím lhůty
                    pro odstoupení od smlouvy a prodávající před uzavřením smlouvy sdělil kupujícímu, že v takovém
                    případě nemá právo na odstoupení od smlouvy,
                  </li>
                  <li>
                    o dodávce zboží nebo služby, jejichž cena závisí na výchylkách finančního trhu nezávisle na vůli
                    prodávajícího a k němuž může dojít během lhůty pro odstoupení od smlouvy,
                  </li>
                  <li>
                    o dodání alkoholických nápojů, které mohou být dodány až po uplynutí třiceti dnů a jejichž cena
                    závisí na výchylkách finančního trhu nezávislých na vůli prodávajícího,
                  </li>
                  <li>o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu,</li>
                  <li>
                    dodávce zboží, které podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s
                    jiným zbožím,
                  </li>
                  <li>
                    dodávce zboží v uzavřeném obalu, které kupující z obalu vyňal a z hygienických důvodů jej není možné
                    vrátit,
                  </li>
                  <li>
                    dodávce zvukové nebo obrazové nahrávky nebo počítačového programu, pokud porušil jejich původní
                    obal,
                  </li>
                  <li>dodávce novin, periodik nebo časopisů,</li>
                  <li>
                    dodání digitálního obsahu, pokud nebyl dodán na hmotném nosiči a byl dodán s předchozím výslovným
                    souhlasem kupujícího před uplynutím lhůty pro odstoupení od smlouvy a prodávající před uzavřením
                    smlouvy sdělil kupujícímu, že v takovém případě nemá právo na odstoupení od smlouvy,
                  </li>
                  <li>v dalších případech uvedených v § 1837 občanského zákoníku.</li>
                </ul>
              </p>

              <p>
                4. Pro dodržení lhůty pro odstoupení od smlouvy musí kupující odeslat prohlášení o odstoupení ve lhůtě
                pro odstoupení od smlouvy.
              </p>

              <p>
                5. Pro odstoupení od kupní smlouvy může kupující využít vzorový formulář k odstoupení od smlouvy
                poskytovaný prodávajícím. Odstoupení od kupní smlouvy zašle kupující na e-mailovou nebo doručovací
                adresu prodávajícího uvedenou v těchto obchodních podmínkách. Prodávající potvrdí kupujícímu bezodkladně
                přijetí formuláře.
              </p>

              <p>
                6. Kupující, který odstoupil od smlouvy, je povinen vrátit prodávajícímu zboží do 14 dnů od odstoupení
                od smlouvy prodávajícímu. Kupující nese náklady spojené s navrácením zboží prodávajícímu, a to i v tom
                případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.
              </p>

              <p>
                7. Odstoupí-li kupující od smlouvy, vrátí mu prodávající bezodkladně, nejpozději však do 14 dnů od
                odstoupení od smlouvy všechny peněžní prostředky včetně nákladů na dodání, které od něho přijal, a to
                stejným způsobem. Prodávající vrátí kupujícímu přijaté peněžení prostředky jiným způsobem jen tehdy,
                pokud s tím kupující souhlasí a pokud mu tím nevzniknou další náklady.
              </p>

              <p>
                8. Jestliže kupující zvolil jiný než nejlevnější způsob dodání zboží, který prodávající nabízí, vrátí
                prodávající kupujícímu náklady na dodání zboží ve výši odpovídající nejlevnějšímu nabízenému způsobu
                dodání zboží.
              </p>

              <p>
                9. Odstoupí-li kupující od kupní smlouvy, není prodávající povinen vrátit přijaté peněžní prostředky
                kupujícímu dříve, než mu kupující zboží předá nebo prokáže, že zboží prodávajícímu odeslal.
              </p>

              <p>
                10. Zboží musí vrátit kupující prodávajícímu nepoškozené, neopotřebené a neznečištěné a je-li to možné,
                v původním obalu. Nárok na náhradu škody vzniklé na zboží je prodávající oprávněn jednostranně započíst
                proti nároku kupujícího na vrácení kupní ceny.
              </p>

              <p>
                11. Prodávající je oprávněn odstoupit od kupní smlouvy z důvodu vyprodání zásob, nedostupnosti zboží,
                nebo když výrobce, dovozce nebo dodavatel zboží přerušil výrobu nebo dovoz zboží. Prodávající
                bezodkladně informuje kupujícího prostřednictvím e-mailové adresy uvedené v objednávce a vrátí ve lhůtě
                14 dnů od oznámení o odstoupení od kupní smlouvy všechny peněžní prostředky včetně nákladů na dodání,
                které od něho na základě smlouvy přijal, a to stejným způsobem, popřípadě způsobem určeným kupujícím.
              </p>

              <h2>VII. Práva z vadného plnění</h2>
              <p>
                1. Prodávající zodpovídá kupujícímu, že zboží při převzetí nemá vady. Zejména prodávající odpovídá
                kupujícímu, že v době, kdy kupující zboží převzal
                <ul>
                  <li>
                    má zboží vlastnosti, které si strany ujednaly, a chybí-li ujednání, má takové vlastnosti, které
                    prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě
                    reklamy jimi prováděné,
                  </li>
                  <li>
                    se zboží hodí k účelu, který pro jeho použití prodávající uvádí nebo ke kterému se zboží tohoto
                    druhu obvykle používá,
                  </li>
                  <li>
                    zboží odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo
                    provedení určeno podle smluveného vzorku nebo předlohy,
                  </li>
                  <li>je zboží v odpovídajícím množství, míře nebo hmotnosti a</li>
                  <li>zboží vyhovuje požadavkům právních předpisů.</li>
                </ul>
              </p>

              <p>
                2. Povinnosti z vadného plnění má prodávající nejméně v takovém rozsahu, v jakém trvají povinnosti z
                vadného plnění výrobce. Kupující je jinak oprávněn uplatnit právo z vady, která se vyskytne u
                spotřebního zboží v době dvaceti čtyř měsíců od převzetí.
              </p>

              <p>
                3. Je-li na prodávaném zboží, na jeho obalu, v návodu připojenému ke zboží nebo v reklamě v souladu s
                jinými právními předpisy uvedena doba, po kterou lze zboží použít, použijí se ustanovení o záruce za
                jakost. Zárukou za jakost se prodávající zavazuje, že zboží bude po určitou dobu způsobilé k použití pro
                obvyklý účel nebo že si zachová obvyklé vlastnosti. Vytkl-li kupující prodávajícímu vadu zboží
                oprávněně, neběží lhůta pro uplatnění práv z vadného plnění ani záruční doba po dobu, po kterou nemůže
                kupující vadné zboží užívat.
              </p>

              <p>
                4. Ustanovení uvedená v předchozím odstavci obchodních podmínek se nepoužijí u zboží prodávaného za
                nižší cenu na vadu, pro kterou byla nižší cena ujednána, na opotřebení zboží způsobené jeho obvyklým
                užíváním, u použitého zboží na vadu odpovídající míře používání nebo opotřebení, kterou zboží mělo při
                převzetí kupujícím, nebo vyplývá-li to z povahy zboží. Právo z vadného plnění kupujícímu nenáleží, pokud
                před převzetím zboží věděl, že zboží má vadu, anebo pokud vadu sám kupující způsobil.
              </p>

              <p>
                5. V případě výskytu vady může kupující prodávajícímu předložit reklamaci a požadovat
                <ul>
                  <li>výměnu za nové zboží</li>
                  <li>opravu zboží,</li>
                  <li>přiměřenou slevu z kupní ceny,</li>
                  <li>odstoupení od smlouvy.</li>
                </ul>
              </p>

              <p>
                Kupující má právo odstoupit od smlouvy,
                <ul>
                  <li>pokud má zboží podstatnou vadu, </li>
                  <li>pokud nemůže věc řádně užívat pro opakovaný výskyt vady nebo vad po opravě,</li>
                  <li>při větším počtu vad zboží.</li>
                </ul>
              </p>

              <p>
                7. Podstatné je takové porušení smlouvy, o němž strana porušující smlouvu již při uzavření smlouvy
                věděla nebo musela vědět, že by druhá strana smlouvu neuzavřela, pokud by toto porušení předvídala.
              </p>

              <p>
                8. U vady, která znamená nepodstatné porušení smlouvy (bez ohledu na to, jde-li o vadu odstranitelnou či
                neodstranitelnou), má kupující nárok na odstranění vady nebo přiměřenou slevu z kupní ceny.
              </p>

              <p>
                9. Vyskytla-li se odstranitelná vada po opravě opakovaně (obvykle třetí reklamace pro stejnou závadu
                nebo čtvrtá pro odlišné závady) nebo má zboží větší počet vad (zpravidla nejméně tři vady současně), má
                kupující právo uplatnit požadavek na slevu z kupní ceny, výměnu zboží nebo odstoupit od smlouvy.
              </p>

              <p>
                10. Při uplatnění reklamace je kupující povinen prodávajícímu sdělit, jaké právo si zvolil. Změna volby
                bez souhlasu prodávajícího je možná jen tehdy, žádal-li kupující opravu vady, která se ukáže být
                neodstranitelná. Nezvolí-li kupující si své právo z podstatného porušení smlouvy včas, má práva stejná
                jako při nepodstatném porušení smlouvy.
              </p>

              <p>
                11. Není-li oprava nebo výměna zboží možná, na základě odstoupení od smlouvy může kupující požadovat
                vrácení kupní ceny v plné výši.
              </p>

              <p>
                12. Pokud prodávající prokáže, že kupující před převzetím o vadě zboží věděl nebo ji sám způsobil, není
                prodávající povinen nároku kupujícího vyhovět.
              </p>

              <p>13. Kupující nemůže reklamovat zlevněné zboží z důvodu, pro který je dané zboží zlevněno.</p>

              <p>
                14. Prodávající je povinen přijmout reklamaci v kterékoli provozovně, v níž je přijetí reklamace možné,
                případně i v sídle nebo místě podnikání. Prodávající je povinen kupujícímu vydat písemné potvrzení o
                tom, kdy kupující právo uplatnil, co je obsahem reklamace a jaký způsob vyřízení reklamace kupující
                požaduje, jakož i potvrzení o datu a způsobu vyřízení reklamace, včetně potvrzení o provedení opravy a
                době jejího trvání, případně písemné odůvodnění zamítnutí reklamace.
              </p>

              <p>
                15. Prodávající nebo jím pověřený pracovník rozhodne o reklamaci ihned, ve složitých případech do tří
                pracovních dnů. Do této lhůty se nezapočítává doba přiměřená podle druhu výrobku či služby potřebná k
                odbornému posouzení vady. Reklamace včetně odstranění vady musí být vyřízena bezodkladně, nejpozději do
                30 dnů ode dne uplatnění reklamace, pokud se prodávající s kupujícím nedohodne na delší lhůtě. Marné
                uplynutí této lhůty se považuje za podstatné porušení smlouvy a kupující má právo od kupní smlouvy
                odstoupit. Za okamžik uplatnění reklamace se považuje okamžik, kdy dojde projev vůle kupujícího
                (uplatnění práva z vadného plnění) prodávajícímu.
              </p>

              <p>16. Prodávající písemně informuje kupujícího o výsledku reklamace.</p>

              <p>
                17. Právo z vadného plnění kupujícímu nenáleží, pokud kupující před převzetím věci věděl, že věc má
                vadu, anebo pokud kupující vadu sám způsobil.
              </p>

              <p>
                18. V případě oprávněné reklamace má kupující právo na náhradu účelně vynaložených nákladů vzniklých v
                souvislosti s uplatněním reklamace. Toto právo může kupující u prodávajícího uplatnit ve lhůtě do
                jednoho měsíce po uplynutí záruční doby, v opačném případě ho soud nemusí přiznat.
              </p>

              <p>19. Volbu způsobu reklamace má kupující.</p>

              <p>
                20. Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí § 1914 až 1925, § 2099 až
                2117 a § 2161 až 2174 občanského zákoníku a zákonem č. 634/1992 Sb., o ochraně spotřebitele.
              </p>

              <p>
                21. Další práva a povinnosti stran související s odpovědností prodávajícího za vady upravuje reklamační
                řád prodávajícího.
              </p>

              <h2>VIII. Doručování</h2>

              <p>
                1. Smluvní strany si mohou veškerou písemnou korespondenci vzájemně doručovat prostřednictvím
                elektronické pošty.
              </p>

              <p>
                2. Kupující doručuje prodávajícímu korespondenci na e-mailovou adresu uvedenou v těchto obchodních
                podmínkách. Prodávající doručuje kupujícímu korespondenci na e-mailovou adresu uvedenou v jeho
                zákaznickém účtu nebo v objednávce.
              </p>

              <h2>IX. Osobní údaje</h2>

              <p>
                1. Všechny informace, které kupující při spolupráci s prodávajícím uvede, jsou důvěrné a bude s nimi tak
                zacházeno. Pokud kupující nedá prodávajícímu písemné svolení, údaje o kupujícím nebude prodávající jiným
                způsobem než za účelem plnění ze smlouvy používat, vyjma e-mailové adresy, na kterou mohou být zasílána
                obchodní sdělení, neboť tento postup umožňuje zákon, pokud není vysloveně odmítnut. Tato sdělení se
                mohou týkat pouze obdobného nebo souvisejícího zboží a lze je kdykoliv jednoduchým způsobem (zasláním
                dopisu, e-mailu nebo proklikem na odkaz v obchodním sdělení) odhlásit. E-mailová adresa bude za tímto
                účelem uchovávána po dobu 3 let od uzavření poslední smlouvy mezi smluvními stranami.
              </p>

              <p>2. Podrobnější informace o ochraně osobních údajů naleznete v Zásadách ochrany osobních údajů ZDE.</p>

              <h2>IX. Mimosoudní řešení sporů</h2>

              <p>
                1. K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce se
                sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa:{' '}
                <a href="https://adr.coi.cz/cs">https://adr.coi.cz/cs</a>. Platformu pro řešení sporů on-line
                nacházející se na internetové adrese{' '}
                <a href="http://ec.europa.eu/consumers/odr">http://ec.europa.eu/consumers/odr</a> je možné využít při
                řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.
              </p>

              <p>
                2. Evropské spotřebitelské centrum Česká republika se sídlem Štěpánská 567/15, 120 00 Praha 2,
                internetová adresa: <a href="http://www.evropskyspotrebitel.cz">http://www.evropskyspotrebitel.cz</a> je
                kontaktním místem podle Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze dne 21. května 2013 o
                řešení spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES
                (nařízení o řešení spotřebitelských sporů on-line).
              </p>

              <p>
                3. Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu
                provádí v rámci své působnosti příslušný živnostenský úřad. Česká obchodní inspekce vykonává ve
                vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele.
              </p>

              <h2>X. Závěrečná ustanovení</h2>

              <p>
                1. Veškerá ujednání mezi prodávajícím a kupujícím se řídí právním řádem České republiky. Pokud vztah
                založený kupní smlouvou obsahuje mezinárodní prvek, pak strany sjednávají, že vztah se řídí právem České
                republiky. Tímto nejsou dotčena práva spotřebitele vyplývající z obecně závazných právních předpisů.
              </p>

              <p>
                2. Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826
                odst. 1 písm. e) občanského zákoníku.
              </p>

              <p>
                3. Všechna práva k webovým stránkám prodávajícího, zejména autorská práva k obsahu, včetně rozvržení
                stránky, fotek, filmů, grafik, ochranných známek, loga a dalšího obsahu a prvků, náleží prodávajícímu.
                Je zakázáno kopírovat, upravovat nebo jinak používat webové stránky nebo jejich část bez souhlasu
                prodávajícího.
              </p>

              <p>
                4. Prodávající nenese odpovědnost za chyby vzniklé v důsledku zásahů třetích osob do internetového
                obchodu nebo v důsledku jeho užití v rozporu s jeho určením. Kupující nesmí při využívání internetového
                obchodu používat postupy, které by mohly mít negativní vliv na jeho provoz a nesmí vykonávat žádnou
                činnost, která by mohla jemu nebo třetím osobám umožnit neoprávněně zasahovat či neoprávněně užít
                programové vybavení nebo další součásti tvořící internetový obchod a užívat internetový obchod nebo jeho
                části či softwarové vybavení takovým způsobem, který by byl v rozporu s jeho určením či účelem.
              </p>

              <p>
                5. Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského
                zákoníku.
              </p>

              <p>
                6. Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není
                přístupná.
              </p>

              <p>
                7. Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva
                a povinnosti vzniklé po dobu účinnosti předchozího znění obchodních podmínek.
              </p>

              <p>8. Přílohou obchodních podmínek je vzorový formulář pro odstoupení od smlouvy.</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageTerms;
