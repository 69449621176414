import { makeIdGenerator } from '../utils';

const getId = makeIdGenerator();

const categoriesDef = [
    {
        name: 'Střešní boxy',
        slug: 'stresni-boxy',
        items: 1,
    },
    {
        name: 'Střešní nosiče',
        slug: 'stresni-nosice',
        items: 2,
    },
];

function walkTree(defs, parent = null) {
    let list = [];
    const siblings = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
            siblings,
        };

        const sibling = {
            id: category.id,
            name: category.name,
            slug: category.slug,
            image: category.image,
            items: category.items,
            customFields: category.customFields,
            parent: null,
            children: [],
            siblings: [],
        };

        siblings.push(sibling);

        const [childrenTree, childrenList] = walkTree(def.children || [], category);

        category.children = childrenTree;
        list = [...list, category, ...childrenList];

        return category;
    });

    return [tree, list];
}

export function prepareCategory(category, depth) {
    let children;

    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }

    return JSON.parse(JSON.stringify({
        ...category,
        parent: category.parent ? prepareCategory(category.parent) : null,
        children,
    }));
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);
