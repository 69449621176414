// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageFaq() {
    const breadcrumb = [
        { title: 'Domů', url: '' },
        { title: 'Často kladené otázky', url: '' },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{`FAQ — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Často kladené otázky" breadcrumb={breadcrumb} />

            <div className="block faq">
                <div className="container">
                    <div className="faq__section">
                        <div className="faq__section-title">
                            <h3>Střešní boxy</h3>
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Jaké velkosti boxů nabízíte?</h6>

                                        <p>
                                            250l, 300l, 330l, 400l a 500l.
                                        </p>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Musím si box namontovat sám?</h6>

                                        <p>
                                            Ne, box Vám zdarma připevníme a následně odmontujeme. Celé to zabere maximálně deset minut.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Kolik stojí půjčení střešního boxu?</h6>

                                        <p>
                                            Podívejte se na náš
                                            {' '}
                                            <Link to="/shop/catalog/stresni-boxy">katalog.</Link>
                                        </p>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Namontujete mi mé vlastní střešní nosiče?</h6>

                                        <p>
                                            Ano, zcela zdarma.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="faq__section">
                        <div className="faq__section-title">
                            <h3>Platba</h3>
                        </div>
                        <div className="faq__section-body">
                            <div className="row">
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Mohu u vás platit platební kartou?</h6>

                                        <p>
                                            Ano, přijímáme platební karty i hotovost.
                                        </p>
                                    </div>
                                </div>
                                <div className="faq__section-column col-12 col-lg-6">
                                    <div className="typography">
                                        <h6>Je potřeba složit nějakou zálohu?</h6>

                                        <p>
                                            Ano, o nějakou zálohu bychom Vás požádali. Pokud box vrátíte v nepoškozeném stavu, zálohu Vám vrátíme. 
                                            Podívejte se prosím na náš
                                            {' '}
                                            <Link to="/shop/catalog/stresni-boxy">katalog.</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SitePageFaq;
