export default [
    {
        title: 'Kč Koruna',
        currency: {
            code: 'CZK',
            symbol: 'Kč',
            name: 'Koruna',
        },
    },
    {
        title: '€ Euro',
        currency: {
            code: 'EUR',
            symbol: '€',
            name: 'Euro',
        },
    },
];
