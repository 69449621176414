// react
import React from 'react';

// data stubs
import theme from '../../data/theme';

export default function FooterContacts() {
  return (
    <div className="site-footer__widget footer-contacts">
      <h5 className="footer-contacts__title">Kontaktujte nás</h5>

      <div className="footer-contacts__text">Naváhejte nás kontaktovat i o víkendu</div>

      <ul className="footer-contacts__contacts">
        <li>
          <i className="footer-contacts__icon fas fa-globe-americas" />
          {theme.contacts.address}
        </li>
        <li>
          <i className="footer-contacts__icon far fa-envelope" />
          <a href={`mailto:${theme.contacts.email}`}>{theme.contacts.email}</a>
        </li>
        <li>
          <i className="footer-contacts__icon fas fa-mobile-alt" />
          {`${theme.contacts.phone}`}
        </li>
        <li>
          <i className="footer-contacts__icon far fa-clock" />
          Po-Ne 7:00 - 22:00
        </li>
      </ul>
    </div>
  );
}
