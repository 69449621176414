export default {
    name: 'Půjčovna střešních boxů v Ústí nad Labem',
    fullName: 'Půjčovna střešních boxů v Ústí nad Labem',
    url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
    author: {
        name: 'Marpel',
        profile_url: 'https://www.marpel.cz',
    },
    contacts: {
        address: 'Masarykova 633, 400 01 Ústí nad Labem',
        email: 'lukas.cermak@yumer.cz',
        phone: '+420 724 181 713',
    },
};
