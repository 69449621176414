// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

function SitePageContactUsAlt() {
  const breadcrumb = [
    { title: 'Domů', url: '' },
    { title: 'Kontakt', url: '' },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Kontakt — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Kontakt" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="card mb-0 contact-us">
            <div className="contact-us__map">
              <iframe
                title="Google Map"
                src="https://maps.google.com/maps?q=Holbrook-Palmer%20Park&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              />
            </div>
            <div className="card-body">
              <div className="contact-us__container">
                <div className="row">
                  <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                    <h4 className="contact-us__header card-title">
                      <FormattedMessage id="sitePageContact.contactUs" defaultMessage="Contact Us" />
                    </h4>

                    <div className="contact-us__address">
                      <p>
                        Masarykova 633, 400 01 Ústí nad Labem
                        <br />
                        Email: <a href="mailto:lukas.cermak@yumer.cz">lukas.cermak@yumer.cz</a>
                        <br />
                        Telefonní číslo: +420 724 181 713
                      </p>

                      <p>
                        <strong>
                          <FormattedMessage id="sitePageContact.openningHours" defaultMessage="Openning Hours" />
                        </strong>
                        <br />
                        Po-Pá: 7:00-17:00
                        <br />
                        So-Ne: dohodou
                      </p>

                      <p>
                        <strong>
                          <FormattedMessage id="sitePageContact.comment" defaultMessage="Comment" />
                        </strong>
                        <br />
                        Sídlíme v garážích za tou velkou a vysokou budovou na Rondelu.
                      </p>
                    </div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <h4 className="contact-us__header card-title">
                      <FormattedMessage id="sitePageContact.leaveUsMessage" defaultMessage="Leave us a Message" />
                    </h4>

                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="form-name">
                            <FormattedMessage id="sitePageContact.yourName" defaultMessage="Your Name" />
                          </label>
                          <FormattedMessage id="sitePageContact.yourName" defaultMessage="Your Name">
                            {(placeholder) => (
                              <input type="text" id="form-name" className="form-control" placeholder={placeholder} />
                            )}
                          </FormattedMessage>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="form-email">Email</label>
                          <FormattedMessage id="sitePageContact.emailAddress" defaultMessage="Email">
                            {(placeholder) => (
                              <input type="email" id="form-email" className="form-control" placeholder={placeholder} />
                            )}
                          </FormattedMessage>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="form-subject">
                          <FormattedMessage id="sitePageContact.subject" defaultMessage="Subject" />
                        </label>
                        <FormattedMessage id="sitePageContact.subject" defaultMessage="Subject">
                          {(placeholder) => (
                            <input type="text" id="form-subject" className="form-control" placeholder={placeholder} />
                          )}
                        </FormattedMessage>
                      </div>
                      <div className="form-group">
                        <label htmlFor="form-message">
                          <FormattedMessage id="sitePageContact.message" defaultMessage="Message" />
                        </label>
                        <textarea id="form-message" className="form-control" rows="4" />
                      </div>
                      <button type="submit" className="btn btn-primary">
                        <FormattedMessage id="sitePageContact.sendMessage" defaultMessage="Send Message" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageContactUsAlt;
