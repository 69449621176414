// react
import React from 'react';

// third-party
import { connect } from 'react-redux';

// application
import Currency from './Currency';

function DisplayPrice(props) {
  const { mode, value } = props;

  return (
    <React.Fragment>
      <Currency value={value} />
      {mode === 'rent' && ' / den'}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  mode: state.toggle.mode,
});

export default connect(mapStateToProps)(DisplayPrice);
