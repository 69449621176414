import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: 'AIzaSyAkSfJim0u_wBcSVchc9ep-X9hHHvhS-74',
    authDomain: 'boxy-287212.firebaseapp.com',
    databaseURL: 'https://boxy-287212.firebaseio.com',
    projectId: 'boxy-287212',
    storageBucket: 'boxy-287212.appspot.com',
    messagingSenderId: '910885903189',
    appId: '1:910885903189:web:0f7adb2b17c2351417eddc',
    measurementId: 'G-ZBCN45X3WB',
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
    promt: 'select_account',
});

// Check if user exists if not create a user
export const createUserProfileDocument = async (userAuth) => {
    if (!userAuth) return null;

    const userReference = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userReference.get();
    if (!snapShot.exists) {
        const { displayName, email } = userAuth;
        const createdAt = new Date();

        let name = '';
        let surname = '';

        if (displayName && displayName.indexOf(' ') != -1) {
            name = displayName.substr(0, displayName.indexOf(' '));
            surname = displayName.substr(displayName.indexOf(' ') + 1);
        }
        else {
            name = displayName;
        }

        try {
            await userReference.set({
                name,
                surname,
                email,
                createdAt,
            });
        } catch (error) {
            console.log(error);
        }
    }

    return userReference;
};

export const signInWithGoogle = () => auth.signInWithPopup(provider);
