// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { connect } from 'react-redux';

// data stubs
import theme from '../../data/theme';

function AccountPageProfile(props) {
  const { login } = props;
  return (
    <div className="card">
      <Helmet>
        <title>{`Profil — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Editovat Profil</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-7 col-xl-6">
            <div className="form-group">
              <label htmlFor="profile-first-name">Jméno</label>
              <input
                id="profile-first-name"
                type="text"
                className="form-control"
                placeholder="Jméno"
                value={login.user.name}
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-last-name">Přijmení</label>
              <input
                id="profile-last-name"
                type="text"
                className="form-control"
                placeholder="Přijmení"
                value={login.user.surname}
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-email">Email</label>
              <input
                id="profile-email"
                type="email"
                className="form-control"
                placeholder="Email"
                value={login.user.email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-phone">Telefon</label>
              <input id="profile-phone" type="text" className="form-control" placeholder="Telefon" />
            </div>

            <div className="form-group mt-5 mb-0">
              <button type="button" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    login: state.login,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageProfile);
