import { LOCALE_CHANGE } from './localeActionTypes';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/pl';

const initialState = 'cs'

export default function localeReducer(state = initialState, action) {
    if (action.type === LOCALE_CHANGE) {
        moment.locale(action.locale);
        return action.locale;
    }
    else {
        moment.locale(state);
    }

    return state;
}
