// react
import React from 'react';

// third-party
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// application
import Dropdown from './Dropdown';
import DropdownCurrency from './DropdownCurrency';
import DropdownLanguage from './DropdownLanguage';

function Topbar() {
  const links = [
    { title: <FormattedMessage id="topbar.aboutUs" defaultMessage="About Us" />, url: '/site/about-us' },
    { title: <FormattedMessage id="topbar.contacts" defaultMessage="Contacts" />, url: '/site/contact-us' },
  ];

  const accountLinks = [
    { title: 'Dashboard', url: '/account/dashboard' },
    { title: 'Editovat Profil', url: '/account/profile' },
    { title: 'Historie Objednávek', url: '/account/orders' },
    { title: 'Addresses', url: '/account/addresses' },
    { title: 'Password', url: '/account/password' },
  ];

  const linksList = links.map((item, index) => (
    <div key={index} className="topbar__item topbar__item--link">
      <Link className="topbar-link" to={item.url}>
        {item.title}
      </Link>
    </div>
  ));

  return (
    <div className="site-header__topbar topbar">
      <div className="topbar__container container">
        <div className="topbar__row">
          <div className="topbar__message">Půjčovna vybavení na dovolenou</div>
          {/* linksList */}
          <div className="topbar__spring" />
          {/*
                    {linksList}
                    <div className="topbar__spring" />
                    <div className="topbar__item">
                        <Dropdown
                            title={<FormattedMessage id="topbar.myAccount" defaultMessage="Můj účet" />}
                            items={accountLinks}
                        />
                    </div>
                    <div className="topbar__item">
                        <DropdownCurrency />
                    </div>
                    <div className="topbar__item">
                        <DropdownLanguage />
                    </div>
                    */}
          <div className="topbar__contact">Podpora +420 724 181 713</div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
