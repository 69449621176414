// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// application
import Indicator from './Indicator';
import { Person20Svg } from '../../svg';

// action
import AsyncAction from '../shared/AsyncAction';
import { logIn, logOut } from '../../store/login';

import useLoginForm from '../account/useLoginForm';
import { auth } from '../../firebase';

function IndicatorAccount(props) {
  const { login, logIn, logOut } = props;

  const { handleLoginSubmit } = useLoginForm();

  const handleLogOut = async () => {
    await auth
      .signOut()
      .then(function () {
        // Sign-out successful.
        logOut();
        console.log('Signed-out successfully');
      })
      .catch(function (error) {
        // An error happened.
        console.log('Singed out failed');
      });
  };

  const LoginForm = () => (
    <Formik
      initialValues={{ loginEmail: '', loginPassword: '' }}
      validationSchema={Yup.object({
        loginEmail: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
        loginPassword: Yup.string().required('Vyžadováno'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          const result = await handleLoginSubmit(values);
          console.log(result);
          console.log(values);
          setSubmitting(false);
        }, 1000);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <React.Fragment>
          <Form className="account-menu__form">
            <div className="account-menu__form-title">Přihlásit</div>
            <div className="form-group">
              <label htmlFor="header-signin-email" className="sr-only">
                Email
              </label>
              <Field
                id="header-signin-email"
                name="loginEmail"
                type="email"
                className={`form-control form-control-sm ${
                  touched.loginEmail && errors.loginEmail ? 'is-invalid' : ''
                }`}
                placeholder="Email"
              />
              <ErrorMessage name="loginEmail" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-password" className="sr-only">
                Heslo
              </label>
              <div className="account-menu__form-forgot">
                <Field
                  id="header-signin-password"
                  type="password"
                  name="loginPassword"
                  className={`form-control form-control-sm ${
                    touched.loginPassword && errors.loginPassword ? 'is-invalid' : ''
                  }`}
                  placeholder="Heslo"
                />
                <Link to="/account/login" className="account-menu__form-forgot-link">
                  Obnovit
                </Link>
                <ErrorMessage name="loginPassword" component="div" className="invalid-feedback" />
              </div>
            </div>
            <div className="form-group account-menu__form-button">
              <button
                type="submit"
                className={classNames('btn btn-primary btn-sm', {
                  'btn-loading': isSubmitting,
                })}
              >
                Přihlásit
              </button>
            </div>
            <div className="account-menu__form-link">
              <Link to="/account/login">Vytvořit účet</Link>
            </div>
          </Form>
          <div className="account-menu__divider" />
          <div className="form-group account-menu__form-button">
            <AsyncAction
              action={() => handleLoginSubmit({}, 'google')}
              render={({ run, loading }) => {
                const classes = classNames('btn btn-primary btn-sm', {
                  'btn-loading': loading,
                });

                return (
                  <button type="button" onClick={run} className={classes}>
                    Přihlásit pomocí Googlu
                  </button>
                );
              }}
            />
          </div>
        </React.Fragment>
      )}
    </Formik>
  );

  const dropdown = (
    <div className="account-menu">
      {!login.user && (
        <React.Fragment>
          <LoginForm />
          <div className="account-menu__divider" />
        </React.Fragment>
      )}
      {login.user && (
        <React.Fragment>
          <Link to="/account/dashboard" className="account-menu__user">
            <div className="account-menu__user-avatar">
              <img src="images/avatars/avatar-3.jpg" alt="" />
            </div>
            <div className="account-menu__user-info">
              <div className="account-menu__user-name">{login.user.name + ' ' + login.user.surname}</div>
              <div className="account-menu__user-email">{login.user.email}</div>
            </div>
          </Link>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li>
              <Link to="/account/profile">Editovat Profil</Link>
            </li>
            <li>
              <Link to="/account/orders">Historie Objednávek</Link>
            </li>
            <li>
              <Link to="/account/addresses">Adresy</Link>
            </li>
            <li>
              <Link to="/account/password">Password</Link>
            </li>
          </ul>
          <div className="account-menu__divider" />
          <ul className="account-menu__links">
            <li>
              <AsyncAction
                action={() => handleLogOut()}
                render={({ run, loading }) => {
                  const classes = classNames('', {
                    'btn-loading': loading,
                  });

                  return (
                    <button type="button" onClick={run} className={classes}>
                      Odhlásit
                    </button>
                  );
                }}
              />
            </li>
          </ul>
        </React.Fragment>
      )}
    </div>
  );

  return <Indicator url="/account" dropdown={dropdown} icon={<Person20Svg />} />;
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = {
  logIn,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
