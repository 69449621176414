// react
import React, { Component } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import * as Yup from 'yup';
import 'yup-phone';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import { cartAddCheckoutValues, cartAddFormValues } from '../../store/cart';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';

class ShopPageCheckout extends Component {
  payments = payments;

  constructor(props) {
    super(props);

    this.state = {
      payment: 'cash',
    };
  }

  handleSubmit = async (formValues, cart, mode, payment) => {
    const { cartAddCheckoutValues } = this.props;
    const token = await this.props.googleReCaptchaProps.executeRecaptcha('homepage');

    let host = 'https://us-central1-boxy-287212.cloudfunctions.net/submitOrderForm';
    //let host = 'http://localhost:5001/boxy-287212/us-central1/submitOrderForm';
    return await axios
      .post(host, {
        formValues,
        cart,
        mode,
        payment,
        token: token,
      })
      .then(() => {
        cartAddCheckoutValues(formValues);
      })
      .catch(() => {
        return { message: 'Objednávku se nepodařilo odeslat.' };
      });
  };

  handlePaymentChange = (event) => {
    if (event.target.checked) {
      this.setState({ payment: event.target.value });
    }
  };

  renderTotals() {
    const { cart, mode } = this.props;

    if (cart.extraLines.length <= 0) {
      return null;
    }

    const extraLines = cart.extraLines.map((extraLine, index) => {
      if (!extraLine.modes.includes(mode)) {
        return;
      }

      return (
        <tr key={index}>
          <th>{extraLine.title}</th>
          <td>
            <Currency value={extraLine.price} />
          </td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <tbody className="checkout__totals-subtotals">
          <tr>
            <th>Mezisoučet</th>
            <td>
              <Currency value={cart.subtotal} />
            </td>
          </tr>
          {extraLines}
        </tbody>
      </React.Fragment>
    );
  }

  renderCart() {
    const { cart } = this.props;

    const items = cart.items.map((item) => {
      return (
        <tr key={item.id}>
          <td>
            {`${item.quantity} × ${item.product.name} `}
            {item.extra.mode === 'rent' ? ` × ${item.extra.days} (den)` : ''}
          </td>
          <td>
            <Currency value={item.total} />
          </td>
        </tr>
      );
    });

    return (
      <table className="checkout__totals">
        <thead className="checkout__totals-header">
          <tr>
            <th>Produkt</th>
            <th>Celkem</th>
          </tr>
        </thead>
        <tbody className="checkout__totals-products">{items}</tbody>
        {this.renderTotals()}
        <tfoot className="checkout__totals-footer">
          <tr>
            <th>Celkem</th>
            <td>
              <Currency value={cart.total} />
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }

  renderPaymentsList() {
    const { payment: currentPayment } = this.state;

    const payments = this.payments.map((payment) => {
      const renderPayment = ({ setItemRef, setContentRef }) => (
        <li className="payment-methods__item" ref={setItemRef}>
          <label className="payment-methods__item-header">
            <span className="payment-methods__item-radio input-radio">
              <span className="input-radio__body">
                <input
                  type="radio"
                  className="input-radio__input"
                  name="checkout_payment_method"
                  value={payment.key}
                  checked={currentPayment === payment.key}
                  onChange={this.handlePaymentChange}
                />
                <span className="input-radio__circle" />
              </span>
            </span>
            <span className="payment-methods__item-title">{payment.title}</span>
          </label>
          <div className="payment-methods__item-container" ref={setContentRef}>
            <div className="payment-methods__item-description text-muted">{payment.description}</div>
          </div>
        </li>
      );

      return (
        <Collapse
          key={payment.key}
          open={currentPayment === payment.key}
          toggleClass="payment-methods__item--active"
          render={renderPayment}
        />
      );
    });

    return (
      <div className="payment-methods">
        <ul className="payment-methods__list">{payments}</ul>
      </div>
    );
  }

  renderCartForm() {
    const { payment } = this.state;
    const { cart, mode } = this.props;
    return (
      <Formik
        initialValues={{
          invoiceFirstName: '',
          invoiceLastName: '',
          invoiceCompanyName: '',
          invoiceCountry: 'Česká republika',
          invoiceStreetAddress: '',
          invoiceCity: '',
          invoicePostCode: '',
          invoiceEmail: '',
          invoicePhone: '',
          checkoutDifferentAddress: false,
          deliveryFirstName: '',
          deliveryLastName: '',
          deliveryCompanyName: '',
          deliveryCountry: '',
          deliveryStreetAddress: '',
          deliveryCity: '',
          deliveryPostCode: '',
          deliveryEmail: '',
          deliveryPhone: '',
          checkoutCreateAccount: '',
          checkoutTerms: false,
        }}
        validationSchema={Yup.object({
          invoiceFirstName: Yup.string().required('Vyžadováno'),
          invoiceLastName: Yup.string().required('Vyžadováno'),
          invoiceStreetAddress: Yup.string().required('Vyžadováno'),
          invoiceCity: Yup.string().required('Vyžadováno'),
          invoicePostCode: Yup.string().required('Vyžadováno'),
          invoiceEmail: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
          invoicePhone: Yup.string().required('Vyžadováno').phone('cz', false, 'Neplatné telefonní číslo'),

          deliveryFirstName: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno'),
            otherwise: Yup.string(),
          }),
          deliveryLastName: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno'),
            otherwise: Yup.string(),
          }),

          deliveryStreetAddress: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno'),
            otherwise: Yup.string(),
          }),

          deliveryCity: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno'),
            otherwise: Yup.string(),
          }),

          deliveryPostCode: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno'),
            otherwise: Yup.string(),
          }),

          deliveryEmail: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
            otherwise: Yup.string(),
          }),

          deliveryPhone: Yup.string().when('checkoutDifferentAddress', {
            is: (checkoutDifferentAddress) => checkoutDifferentAddress,
            then: Yup.string().required('Vyžadováno').phone('cz', false, 'Neplatné telefonní číslo'),
            otherwise: Yup.string(),
          }),

          checkoutTerms: Yup.bool().oneOf([true], 'Souhlas je vyžadován'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            const currentPayment = this.payments.find((item) => item.key === payment);
            this.handleSubmit(values, cart, mode, currentPayment);
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ values, errors, touched, isValid, isSubmitting }) => (
          <React.Fragment>
            <Form className="row">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                  <div className="card-body">
                    <h3 className="card-title">Fakturační údaje</h3>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="invoice-first-name">Jméno</label>
                        <Field
                          type="text"
                          className={`form-control ${
                            touched.invoiceFirstName && errors.invoiceFirstName ? 'is-invalid' : ''
                          }`}
                          id="invoice-first-name"
                          name="invoiceFirstName"
                          placeholder="Jméno"
                        />
                        <ErrorMessage name="invoiceFirstName" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="invoice-last-name">Přijmení</label>
                        <Field
                          type="text"
                          className={`form-control ${
                            touched.invoiceLastName && errors.invoiceLastName ? 'is-invalid' : ''
                          }`}
                          id="checkout-last-name"
                          name="invoiceLastName"
                          placeholder="Přijmení"
                        />
                        <ErrorMessage name="invoiceLastName" component="div" className="invalid-feedback" />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="invoice-company-name">
                        Firma <span className="text-muted">(Volitelné)</span>
                      </label>
                      <Field
                        type="text"
                        className={`form-control ${
                          touched.invoiceCompanyName && errors.invoiceCompanyName ? 'is-invalid' : ''
                        }`}
                        id="invoice-company-name"
                        name="invoiceCompanyName"
                        placeholder="Firma"
                      />
                      <ErrorMessage name="invoiceCompanyName" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="invoice-country">Země</label>
                      <Field
                        as="select"
                        className={`form-control ${
                          touched.invoiceCountry && errors.invoiceCountry ? 'is-invalid' : ''
                        }`}
                        id="invoice-country"
                        name="invoiceCountry"
                      >
                        <option>Česká republika</option>
                        <option>Německo</option>
                        <option>Slovensko</option>
                        <option>Rakousko</option>
                      </Field>
                      <ErrorMessage name="invoiceCountry" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="invoice-street-address">Ulice</label>
                      <Field
                        type="text"
                        className={`form-control ${
                          touched.invoiceStreetAddress && errors.invoiceStreetAddress ? 'is-invalid' : ''
                        }`}
                        id="invoice-street-address"
                        name="invoiceStreetAddress"
                        placeholder="Ulice"
                      />
                      <ErrorMessage name="invoiceStreetAddress" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="invoice-city">Město</label>
                      <Field
                        type="text"
                        className={`form-control ${touched.invoiceCity && errors.invoiceCity ? 'is-invalid' : ''}`}
                        id="invoice-city"
                        name="invoiceCity"
                        placeholder="Město"
                      />
                      <ErrorMessage name="invoiceCity" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="invoice-post-code">PSČ</label>
                      <Field
                        type="text"
                        className={`form-control ${
                          touched.invoicePostCode && errors.invoicePostCode ? 'is-invalid' : ''
                        }`}
                        id="invoice-post-code"
                        name="invoicePostCode"
                        placeholder="PSČ"
                      />
                      <ErrorMessage name="invoicePostCode" component="div" className="invalid-feedback" />
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="invoice-email">Email</label>
                        <Field
                          type="email"
                          className={`form-control ${touched.invoiceEmail && errors.invoiceEmail ? 'is-invalid' : ''}`}
                          id="invoice-email"
                          name="invoiceEmail"
                          placeholder="Email"
                        />
                        <ErrorMessage name="invoiceEmail" component="div" className="invalid-feedback" />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="invoice-phone">Telefon</label>
                        <Field
                          type="text"
                          className={`form-control ${touched.invoicePhone && errors.invoicePhone ? 'is-invalid' : ''}`}
                          id="invoice-phone"
                          name="invoicePhone"
                          placeholder="Telefon"
                        />
                        <ErrorMessage name="invoicePhone" component="div" className="invalid-feedback" />
                      </div>
                    </div>

                    {/*<div className="form-group">
                      <div className="form-check">
                        <span className="form-check-input input-check">
                          <span className="input-check__body">
                            <Field
                              className="input-check__input"
                              type="checkbox"
                              id="checkout-create-account"
                              name="checkoutCreateAccount"
                            />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                          </span>
                        </span>
                        <label className="form-check-label" htmlFor="checkout-create-account">
                          Vytvořit účet?
                        </label>
                      </div>
                    </div>*/}
                  </div>
                  <div className="card-divider" />
                  <div className="card-body">
                    <h3 className="card-title">Doručovací údaje</h3>
                    {this.props.mode === 'sell' && (
                      <div className="form-group">
                        <div className="form-check">
                          <span className="form-check-input input-check">
                            <span className="input-check__body">
                              <Field
                                className="input-check__input"
                                type="checkbox"
                                id="checkout-different-address"
                                name="checkoutDifferentAddress"
                              />
                              <span className="input-check__box" />
                              <Check9x7Svg className="input-check__icon" />
                            </span>
                          </span>
                          <label className="form-check-label" htmlFor="checkout-different-address">
                            Poslat na jinou adresu?
                          </label>
                        </div>
                      </div>
                    )}

                    {values.checkoutDifferentAddress && (
                      <React.Fragment>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="delivery-first-name">Jméno</label>
                            <Field
                              type="text"
                              className={`form-control ${
                                touched.deliveryFirstName && errors.deliveryFirstName ? 'is-invalid' : ''
                              }`}
                              id="delivery-first-name"
                              name="deliveryFirstName"
                              placeholder="Jméno"
                            />
                            <ErrorMessage name="deliveryFirstName" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="delivery-last-name">Přijmení</label>
                            <Field
                              type="text"
                              className={`form-control ${
                                touched.deliveryLastName && errors.deliveryLastName ? 'is-invalid' : ''
                              }`}
                              id="delivery-last-name"
                              name="deliveryLastName"
                              placeholder="Přijmení"
                            />
                            <ErrorMessage name="deliveryLastName" component="div" className="invalid-feedback" />
                          </div>
                        </div>

                        <div className="form-group">
                          <label htmlFor="delivery-company-name">
                            Firma <span className="text-muted">(Volitelné)</span>
                          </label>
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.deliveryCompanyName && errors.deliveryCompanyName ? 'is-invalid' : ''
                            }`}
                            id="delivery-company-name"
                            name="deliveryCompanyName"
                            placeholder="Firma"
                          />
                          <ErrorMessage name="deliveryCompanyName" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="delivery-country">Země</label>
                          <Field
                            as="select"
                            className={`form-control ${
                              touched.deliveryCountry && errors.deliveryCountry ? 'is-invalid' : ''
                            }`}
                            id="delivery-country"
                            name="deliveryCountry"
                          >
                            <option>Česká republika</option>
                            <option>Německo</option>
                            <option>Slovensko</option>
                            <option>Rakousko</option>
                          </Field>
                          <ErrorMessage name="deliveryCountry" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="delivery-street-address">Ulice</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.deliveryStreetAddress && errors.deliveryStreetAddress ? 'is-invalid' : ''
                            }`}
                            id="delivery-street-address"
                            name="deliveryStreetAddress"
                            placeholder="Ulice"
                          />
                          <ErrorMessage name="deliveryStreetAddress" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-group">
                          <label htmlFor="delivery-city">Město</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.deliveryCity && errors.deliveryCity ? 'is-invalid' : ''
                            }`}
                            id="delivery-city"
                            name="deliveryCity"
                            placeholder="Město"
                          />
                          <ErrorMessage name="deliveryCity" component="div" className="invalid-feedback" />
                        </div>
                        <div className="form-group">
                          <label htmlFor="delivery-post-code">PSČ</label>
                          <Field
                            type="text"
                            className={`form-control ${
                              touched.deliveryPostCode && errors.deliveryPostCode ? 'is-invalid' : ''
                            }`}
                            id="delivery-post-code"
                            name="deliveryPostCode"
                            placeholder="PSČ"
                          />
                          <ErrorMessage name="deliveryPostCode" component="div" className="invalid-feedback" />
                        </div>

                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="delivery-email">Email</label>
                            <Field
                              type="email"
                              className={`form-control ${
                                touched.deliveryEmail && errors.deliveryEmail ? 'is-invalid' : ''
                              }`}
                              id="delivery-email"
                              name="deliveryEmail"
                              placeholder="Email"
                            />
                            <ErrorMessage name="deliveryEmail" component="div" className="invalid-feedback" />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="delivery-phone">Telefon</label>
                            <Field
                              type="text"
                              className={`form-control ${
                                touched.deliveryPhone && errors.deliveryPhone ? 'is-invalid' : ''
                              }`}
                              id="delivery-phone"
                              name="deliveryPhone"
                              placeholder="Telefon"
                            />
                            <ErrorMessage name="deliveryPhone" component="div" className="invalid-feedback" />
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div className="form-group">
                      <label htmlFor="checkout-comment">
                        Poznámky <span className="text-muted">(Volitelné)</span>
                      </label>
                      <Field
                        as="textarea"
                        id="checkout-comment"
                        name="checkoutComment"
                        className="form-control"
                        rows="4"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                <div className="card mb-0">
                  <div className="card-body">
                    <h3 className="card-title">Vaše objednávka</h3>

                    {this.renderCart()}

                    {this.renderPaymentsList()}

                    <div className="checkout__agree form-group">
                      <div
                        className={`form-check ${touched.checkoutTerms && errors.checkoutTerms ? 'is-invalid' : ''}`}
                      >
                        <span className="form-check-input input-check">
                          <span className="input-check__body">
                            <Field
                              className="input-check__input"
                              type="checkbox"
                              id="checkout-terms"
                              name="checkoutTerms"
                            />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                          </span>
                        </span>
                        <label className="form-check-label" htmlFor="checkout-terms">
                          Souhlasím s <Link to="/site/terms">obchodními podmínkami.</Link>
                        </label>
                      </div>
                      <ErrorMessage name="checkoutTerms" component="div" className="invalid-feedback" />
                    </div>
                    <button
                      type="submit"
                      className={classNames('btn btn-primary btn-xl btn-block', {
                        'btn-loading': isSubmitting,
                      })}
                      disabled={!isValid || Object.keys(touched).length === 0}
                    >
                      Objednat
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  }

  render() {
    const { cart, login } = this.props;

    if (cart.items.length < 1) {
      return <Redirect to="cart" />;
    }

    if (cart.checkoutSuccessful) {
      return <Redirect to="/shop/checkout/success" />;
    }

    const breadcrumb = [
      { title: 'Domů', url: '' },
      { title: 'Košík', url: '/shop/cart' },
      { title: 'Pokladna', url: '' },
    ];

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Pokladna — ${theme.name}`}</title>
        </Helmet>

        <PageHeader header="Pokladna" breadcrumb={breadcrumb} />

        <div className="checkout block">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-3">
                {!login.user && (
                  <div className="alert alert-primary alert-lg">
                    Pravidelný zákazník? <Link to="/account/login">Přihlašte se.</Link>
                  </div>
                )}
                {this.renderCartForm()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  login: state.login,
  mode: state.toggle.mode,
});

const mapDispatchToProps = {
  cartAddCheckoutValues,
};

const ReCaptchaComponent = connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
export default withGoogleReCaptcha(ReCaptchaComponent);
