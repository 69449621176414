// react
import React, { useState } from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';
import { cartClear } from '../../store/cart';

// data stubs
import theme from '../../data/theme';
import { url } from '../../services/utils';

function ShopPageOrderSuccess(props) {
  const { cart, cartClear } = props;
  const [order, setOrder] = useState(cart);

  if (order.items.length < 1) {
    return <Redirect to="/shop/cart" />;
  }

  cartClear();

  const items = order.items.map((item) => {
    const options = (item.options || []).map((option) => (
      <li className="order-list__options-item">
        <span className="order-list__options-label">
          {option.label}
          {': '}
        </span>
        <span className="order-list__options-value">{option.value}</span>
      </li>
    ));

    return (
      <tr>
        <td className="order-list__column-image">
          <div className="product-image">
            <Link to={url.product(item)} className="product-image__body">
              <img className="product-image__img" src={item.product.images[0]} alt="" />
            </Link>
          </div>
        </td>
        <td className="order-list__column-product">
          <Link to={url.product(item)}>{item.product.name}</Link>
          {options.length > 0 && (
            <div className="order-list__options">
              <ul className="order-list__options-list">{options}</ul>
            </div>
          )}
        </td>
        <td className="order-list__column-quantity" data-title="Qty:">
          {item.quantity}
          {item.extra.mode === 'rent' ? ` × ${item.extra.days} (den)` : ''}
        </td>
        <td className="order-list__column-total">
          <Currency value={item.total} />
        </td>
      </tr>
    );
  });

  const additionalLines = order.extraLines.map((line) => (
    <tr>
      <th className="order-list__column-label" colSpan="3">
        {line.title}
      </th>
      <td className="order-list__column-total">
        <Currency value={line.price} />
      </td>
    </tr>
  ));

  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order Success — ${theme.name}`}</title>
      </Helmet>

      <div className="container">
        <div className="order-success__body">
          <div className="order-success__header">
            <Check100Svg className="order-success__icon" />
            <h1 className="order-success__title">Děkujeme</h1>
            <div className="order-success__subtitle">Vaše objednávka byla doručena</div>
            <div className="order-success__actions">
              <Link to="/" className="btn btn-xs btn-secondary">
                Zpět na úvodní stránku
              </Link>
            </div>
          </div>

          <div className="order-success__meta">
            <ul className="order-success__meta-list">
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Číslo objednávky:</span>
                <span className="order-success__meta-value">{`#${order.id}`}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Vytvořeno:</span>
                <span className="order-success__meta-value">{order.date}</span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Celkem:</span>
                <span className="order-success__meta-value">
                  <Currency value={order.total} />
                </span>
              </li>
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Platební metoda:</span>
                <span className="order-success__meta-value">{order.paymentMethod}</span>
              </li>
            </ul>
          </div>

          <div className="card">
            <div className="order-list">
              <table>
                <thead className="order-list__header">
                  <tr>
                    <th className="order-list__column-label" colSpan="2">
                      Produkt
                    </th>
                    <th className="order-list__column-quantity">Počet</th>
                    <th className="order-list__column-total">Celkem</th>
                  </tr>
                </thead>
                <tbody className="order-list__products">{items}</tbody>
                {additionalLines.length > 0 && (
                  <tbody className="order-list__subtotals">
                    <tr>
                      <th className="order-list__column-label" colSpan="3">
                        Mezisoučet
                      </th>
                      <td className="order-list__column-total">
                        <Currency value={order.subtotal} />
                      </td>
                    </tr>
                    {additionalLines}
                  </tbody>
                )}
                <tfoot className="order-list__footer">
                  <tr>
                    <th className="order-list__column-label" colSpan="3">
                      Celkem
                    </th>
                    <td className="order-list__column-total">
                      <Currency value={order.total} />
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>

          <div className="row mt-3 no-gutters mx-n2">
            {order.checkoutValues.shippingAddress && (
              <div className="col-sm-6 col-12 px-2">
                <div className="card address-card">
                  <div className="address-card__body">
                    <div className="address-card__badge address-card__badge--muted">Doručovací adresa</div>
                    <div className="address-card__name">
                      {`${order.checkoutValues.shippingAddress.firstName} ${order.checkoutValues.shippingAddress.lastName}`}
                    </div>
                    <div className="address-card__row">
                      {order.checkoutValues.shippingAddress.country}
                      <br />
                      {`${order.checkoutValues.shippingAddress.postcode}, ${order.checkoutValues.shippingAddress.city}`}
                      <br />
                      {order.checkoutValues.shippingAddress.address}
                    </div>
                    <div className="address-card__row">
                      <div className="address-card__row-title">Telefon</div>
                      <div className="address-card__row-content">{order.checkoutValues.shippingAddress.phone}</div>
                    </div>
                    <div className="address-card__row">
                      <div className="address-card__row-title">Email</div>
                      <div className="address-card__row-content">{order.checkoutValues.shippingAddress.email}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">Fakturační adresa</div>
                  <div className="address-card__name">
                    {`${order.checkoutValues.billingAddress.firstName} ${order.checkoutValues.billingAddress.lastName}`}
                  </div>
                  <div className="address-card__row">
                    {order.checkoutValues.billingAddress.country}
                    <br />
                    {`${order.checkoutValues.billingAddress.postcode}, ${order.checkoutValues.billingAddress.city}`}
                    <br />
                    {order.checkoutValues.billingAddress.address}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Telefon</div>
                    <div className="address-card__row-content">{order.checkoutValues.billingAddress.phone}</div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email</div>
                    <div className="address-card__row-content">{order.checkoutValues.billingAddress.email}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  cartClear,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSuccess);
