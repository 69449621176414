// react
import React from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import Help from './Help';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';

export default function Footer() {
  const informationLinks = [
    { title: 'O nás', url: '/site/about-us' },
    { title: 'Často kladené otázky', url: '/site/faq' },
    { title: 'Kontaktuje nás', url: '/site/contact-us' },
    { title: 'Obchodní podmínky', url: '/site/terms' },
  ];

  const accountLinks = [{ title: 'Umístění obchodu', url: '' }];

  return (
    <div className="site-footer">
      <div className="container">
        <div className="site-footer__widgets">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-4">
              <FooterContacts />
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <FooterLinks title="Důležité" items={informationLinks} />
            </div>
            {/* <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Kontakt" items={accountLinks} />
                        </div> */}
            <div className="col-12 col-md-12 col-lg-4">
              <Help />
            </div>
            {/*
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                        */}
          </div>
        </div>

        <div className="site-footer__bottom">
          <div className="site-footer__copyright">
            Web vytvořil{' '}
            <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
              {theme.author.name}
            </a>
            . Chráněno systémem reCAPTCHA od Googlu.{' '}
            <a href="https://policies.google.com/privacy?hl=cs">Zásady ochrany osobních údajů</a>,{' '}
            <a href="https://policies.google.com/terms?hl=cs">smluvní podmínky</a>.
          </div>
          {/* <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div> */}
        </div>
      </div>
      <ToTop />
    </div>
  );
}
