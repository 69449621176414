// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';

export default function AccountPageEditAddress() {
  return (
    <div className="card">
      <Helmet>
        <title>{`Editovat adresu — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Editovat adresu</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-first-name">Jméno</label>
                <input type="text" className="form-control" id="checkout-first-name" placeholder="Jméno" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-last-name">Přijmení</label>
                <input type="text" className="form-control" id="checkout-last-name" placeholder="Přijmení" />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="checkout-company-name">
                Společnost <span className="text-muted">(Volitelné)</span>
              </label>
              <input type="text" className="form-control" id="checkout-company-name" placeholder="Společnost" />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-country">Země</label>
              <select id="checkout-country" className="form-control form-control-select2">
                <option>Česká republika</option>
                <option>Německo</option>
                <option>Slovensko</option>
                <option>Rakousko</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="checkout-street-address">Ulice</label>
              <input type="text" className="form-control" id="checkout-street-address" placeholder="Ulice" />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-address">
                Číslo bytu, jiné. <span className="text-muted">(Volitelné)</span>
              </label>
              <input type="text" className="form-control" id="checkout-address" />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-city">Město</label>
              <input type="text" className="form-control" id="checkout-city" />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-state">Kraj</label>
              <input type="text" className="form-control" id="checkout-state" />
            </div>
            <div className="form-group">
              <label htmlFor="checkout-postcode">PSČ</label>
              <input type="text" className="form-control" id="checkout-postcode" />
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-email">Email</label>
                <input type="email" className="form-control" id="checkout-email" placeholder="Email" />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-phone">Telefon</label>
                <input type="text" className="form-control" id="checkout-phone" placeholder="Telefon" />
              </div>
            </div>

            <div className="form-group mt-3 mb-0">
              <button className="btn btn-primary" type="button">
                Uložit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
