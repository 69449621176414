// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// application
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// action
import AsyncAction from '../shared/AsyncAction';

// data stubs
import theme from '../../data/theme';

import { logIn, logOut } from '../../store/login';
import { auth, signInWithGoogle, createUserProfileDocument } from '../../firebase';
import useLoginForm from './useLoginForm';

function AccountPageLogin(props) {
  const { login, logIn, logOut } = props;

  const breadcrumb = [
    { title: 'Domů', url: '' },
    { title: 'Můj účet', url: '' },
  ];

  const [loginErrors, setLoginErrors] = useState({});
  const [registerErrors, setRegisterErrors] = useState({});

  const { handleLoginSubmit } = useLoginForm();

  // user is logged in, let's display dashboard
  if (login.user) {
    return <Redirect to="/account/dashboard" />;
  }

  const LoginForm = () => (
    <Formik
      initialValues={{ loginEmail: '', loginPassword: '' }}
      validationSchema={Yup.object({
        loginEmail: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
        loginPassword: Yup.string().required('Vyžadováno'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          const result = await handleLoginSubmit(values);
          console.log(result);
          console.log(values);
          setLoginErrors(result);
          setSubmitting(false);
        }, 1000);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <React.Fragment>
          <h3 className="card-title">Přihlásit</h3>
          {loginErrors.message && <div className="alert alert-danger mb-3">{loginErrors.message}</div>}
          {loginErrors.success && <div className="alert alert-success mb-3">{loginErrors.success}</div>}
          <Form>
            <div className="form-group">
              <label htmlFor="loginEmail">Email</label>
              <Field
                id="loginEmail"
                name="loginEmail"
                type="email"
                className={`form-control ${touched.loginEmail && errors.loginEmail ? 'is-invalid' : ''}`}
                placeholder="Vložte email"
              />
              <ErrorMessage name="loginEmail" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label htmlFor="loginPassword">Heslo</label>
              <Field
                id="loginPassword"
                name="loginPassword"
                type="password"
                className={`form-control ${touched.loginPassword && errors.loginPassword ? 'is-invalid' : ''}`}
                placeholder="Heslo"
              />
              <small className="form-text text-muted">
                <Link to="/">Zapomenuté heslo</Link>
              </small>
              <ErrorMessage name="loginPassword" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <div className="form-check">
                <span className="form-check-input input-check">
                  <span className="input-check__body">
                    <Field id="login-remember" name="login-remember" type="checkbox" className="input-check__input" />
                    <span className="input-check__box" />
                    <Check9x7Svg className="input-check__icon" />
                  </span>
                </span>
                <label className="form-check-label" htmlFor="login-remember">
                  Zapamatovat
                </label>
              </div>
            </div>
            <button
              type="submit"
              className={classNames('btn btn-primary mt-2 mt-md-3 mt-lg-4', {
                'btn-loading': isSubmitting,
              })}
            >
              Přihlásit
            </button>
            <div>
              <AsyncAction
                action={() => handleLoginSubmit({}, 'google')}
                render={({ run, loading }) => {
                  const classes = classNames('btn btn-primary mt-2 mt-md-3 mt-lg-4', {
                    'btn-loading': loading,
                  });

                  return (
                    <button type="button" onClick={run} className={classes}>
                      Přihlásit pomocí Googlu
                    </button>
                  );
                }}
              />
            </div>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );

  const handleRegisterSubmit = async (values) => {
    return await auth
      .createUserWithEmailAndPassword(values.registerEmail, values.registerPassword)
      .then(async (authUser) => {
        const user = await createUserProfileDocument(authUser);
        logIn(user);
        return { success: 'Úspěšně zaregistrováno' };
      })
      .catch((reason) => {
        return reason;
      });
  };

  const RegisterForm = () => (
    <Formik
      initialValues={{ registerEmail: '', registerPassword: '', registerPasswordConfirm: '' }}
      validationSchema={Yup.object({
        registerEmail: Yup.string().email('Neplatná emailová adresa').required('Vyžadováno'),
        registerPassword: Yup.string().required('Vyžadováno'),
        registerPasswordConfirm: Yup.string().required('Vyžadováno'),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        const result = await handleRegisterSubmit(values);
        setRegisterErrors(result);
        setSubmitting(false);
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <React.Fragment>
          <h3 className="card-title">Zaregistrovat</h3>
          {registerErrors.message && <div className="alert alert-danger mb-3">{registerErrors.message}</div>}
          {registerErrors.success && <div className="alert alert-success mb-3">{registerErrors.success}</div>}
          <Form>
            <div className="form-group">
              <label htmlFor="registerEmail">Email</label>
              <Field
                id="registerEmail"
                name="registerEmail"
                type="email"
                className={`form-control ${touched.registerEmail && errors.registerEmail ? 'is-invalid' : ''}`}
                placeholder="Vložte email"
              />
              <ErrorMessage name="registerEmail" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label htmlFor="registerPassword">Heslo</label>
              <Field
                id="registerPassword"
                name="registerPassword"
                type="password"
                className={`form-control ${touched.registerPassword && errors.registerPassword ? 'is-invalid' : ''}`}
                placeholder="Heslo"
              />
              <ErrorMessage name="registerPassword" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group">
              <label htmlFor="registerPasswordConfirm">Heslo znovu</label>
              <Field
                id="registerPasswordConfirm"
                name="registerPasswordConfirm"
                type="password"
                className={`form-control ${
                  touched.registerPasswordConfirm && errors.registerPasswordConfirm ? 'is-invalid' : ''
                }`}
                placeholder="Heslo"
              />
              <ErrorMessage name="registerPasswordConfirm" component="div" className="invalid-feedback" />
            </div>
            <button
              type="submit"
              className={classNames('btn btn-primary mt-2 mt-md-3 mt-lg-4', {
                'btn-loading': isSubmitting,
              })}
            >
              Zaregistrovat
            </button>
          </Form>
        </React.Fragment>
      )}
    </Formik>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Přihlásit — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Můj účet" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card flex-grow-1 mb-md-0">
                <div className="card-body">
                  <LoginForm />
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex mt-4 mt-md-0">
              <div className="card flex-grow-1 mb-0">
                <div className="card-body">
                  <RegisterForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = {
  logIn,
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);
