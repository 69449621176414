// react

import { auth, signInWithGoogle } from '../../firebase';

function useLoginForm() {
  const handleLoginSubmit = async (values, type) => {
    if (type === 'google') {
      return await signInWithGoogle();
    }

    return await auth
      .signInWithEmailAndPassword(values.loginEmail, values.loginPassword)
      .then(() => {
        return { success: 'Byli jste přihlášeni.' };
      })
      .catch((reason) => {
        return reason;
      });
  };

  return { handleLoginSubmit };
}

export default useLoginForm;
